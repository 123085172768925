
import { gql } from 'graphql-request'
import { GQL_FRAG_LOCATION_FIELDS } from '../locations/fragments';
import { GQL_FRAG_PROFILE_FIELDS } from './fragments';

export const GQL_CREATE_PROFILE = gql`
  ${GQL_FRAG_PROFILE_FIELDS}
  mutation createProfile($cognitoSub: String!, $email: String!) {
    insert_profile(objects: {cognito_sub: $cognitoSub, username: $email}) {
      returning {
        ...ProfileFields
      }
    }
  }`;

export const GQL_ADD_COGNITO_ID_TO_PROFILE = gql`
  ${GQL_FRAG_PROFILE_FIELDS}
  ${GQL_FRAG_LOCATION_FIELDS}
  mutation addCognitoIdToProfile($email: String!, $cognitoSub: String!) {
    update_profile(where: {username: {_eq: $email}}, _set: {cognito_sub: $cognitoSub}) {
      returning {
        ...ProfileFields
        default_location {
          id
          formatted_address
          geom
        }
        locations {
          ...LocationFields
        }
      }
    }
  }`;


export const GQL_UPDATE_PROFILE = gql`
  ${GQL_FRAG_PROFILE_FIELDS}
  ${GQL_FRAG_LOCATION_FIELDS}
  mutation update_profile($id: Int!, $changes: profile_set_input) {
    update_profile(where: {id: {_eq: $id}}, _set: $changes) {
      returning {
        ...ProfileFields
        default_location {
          id
          formatted_address
          geom
        }
        locations {
          ...LocationFields
          images {
            id
            path
            user_id
            location_id
          }
        }
      }
    }
  }`