import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ReactGA4 from "react-ga4";
import { openModal } from '../common/modals/modalActions'
import logo from '../common/assets/shared-earth-horizontal.svg'
import saLogo from '../common/assets/Sustainable-America-Alternate-Logo.svg'
import { history } from '../common/utils/history';
import { isUIWebView } from '../common/utils/helpers';
// import usePageTracking from '../common/hooks/usePageTracking'

const MobileNav = () => {
  const dispatch = useDispatch()
  const [expandedMobileNav, setExpandedMobileNav] = useState(false)
  // const { trackEvent } = usePageTracking()
  return (
    <>
      <div id='mobileNav' className='bg-white shadow d-block d-md-none'>
        <div className='bg-light mobile-header'>
          <div className='container position-relative'>
            <div className='d-flex justify-content-between align-items-center'>
              <NavLink
                onClick={() => setExpandedMobileNav(false)}
                to='/'
                className='nav-logo d-flex'
              >
                <img
                  className='mx-auto img-fluid'
                  src={logo}
                  alt='shared-earth'
                />
              </NavLink>
              <button
                onClick={() => {
                  setExpandedMobileNav(!expandedMobileNav)
                }}
                id='toggle-mobile-app-nav'
                className='bg-transparent'
              >
                <i className='icon-custom icon-hamburger' />
                <span className='sr-only'>Menu</span>
              </button>
            </div>
          </div>
        </div>
        <ul className='nav mobile-app-nav justify-content-between align-items-center'>
          <NavLink
            to='/map'
            className='nav-link'
            onClick={() => setExpandedMobileNav(false)}
          >
            <i className='icon-custom icon-map' />
            <span className='sr-only'>Map</span>
          </NavLink>
          <NavLink
            to='/list'
            className='nav-link'
            onClick={() => setExpandedMobileNav(false)}
          >
            <i className='icon-custom icon-list' />
            <span className='sr-only'>List</span>
          </NavLink>
          <NavLink
            to='/messages'
            className='nav-link'
            onClick={() => setExpandedMobileNav(false)}
          >
            <i className='icon-custom icon-messages' />
            <span className='sr-only'>Messages</span>
          </NavLink>
          <NavLink
            to='/profile'
            className='nav-link'
            onClick={() => setExpandedMobileNav(false)}
          >
            <i className='icon-custom icon-profile' />
            <span className='sr-only'>My Profile</span>
          </NavLink>
        </ul>
      </div>
      <div
        className={`mobile-nav-drawer ${expandedMobileNav ? 'expanded' : ''}`}
      >
        <div className='d-flex justify-content-center mt-3'>
          <ul className='nav mobile-secondary-nav flex-column'>
            <li
              onClick={() => setExpandedMobileNav(false)}
              className='nav-item border-bottom border-mid'
            >
                <button
                  onClick={() => {
                    ReactGA4.event({
                      category: 'Manual Donation Modal Event',
                      action: 'Donation Modal Open'
                    })
                    if (!isUIWebView) {
                      history.push('?showDonationModal=true')
                      dispatch(openModal('DonateModal'))
                    } else {
                      const a = document.createElement('a')
                      a.setAttribute('href', 'https://sharedearth.com/donate')
                      a.setAttribute('target', '_blank')

                      const dispatch = document.createEvent('HTMLEvents')
                      dispatch.initEvent('click', true, true)
                      a.dispatchEvent(dispatch)
                    }
                  }}
                  className='nav-link bg-transparent'
                >
                  <i className='icon-custom icon-donate' />
                  <span>Donate</span>
                </button>
            </li>
            <li className='nav-item border-bottom border-mid'>
              <a
                href={process.env.REACT_APP_SHOPIFY_URL}
                className='nav-link'
                target='_blank'
                rel='noreferrer'
              >
                <i className='icon-custom icon-merchandise' />
                <span>Merchandise</span>
              </a>
            </li>
            <li
              onClick={() => setExpandedMobileNav(false)}
              className='nav-item border-bottom border-mid'
            >
              <NavLink to='/resources' className='nav-link'>
                <i className='icon-custom icon-resources' />
                <span>Resources</span>
              </NavLink>
            </li>
            <li
              onClick={() => setExpandedMobileNav(false)}
              className='nav-item'
            >
              <NavLink to='/settings' className='nav-link'>
                <i className='icon-custom icon-settings' />
                <span>Settings</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <footer className='footer-mobile-nav'>
          <div className='row align-items-center justify-content-center no-gutters'>
            <div className='col-6 border-right border-white'>
              <p className='mx-3 text-uppercase text-white text-center'>
                <small>Presented By</small>
              </p>
            </div>
            <div className='col-6'>
              <a
                href='https://sustainableamerica.org/'
                rel='noreferrer'
                target='_blank'
              >
                <img
                  className='mx-3 img-fluid'
                  src={saLogo}
                  alt='shared-earth'
                />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default MobileNav
