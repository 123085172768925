import { gql } from 'graphql-request'

export const GQL_FRAG_LOCATION_FIELDS = gql`
  fragment LocationFields on locations_location {
    id
    user_id
    # latitude
    # longitude
    about
    responsibilities # This gives an ID that will need to be mapped
    experience # This gives an ID that will need to be mapped
    compensation # This gives an ID that will need to be mapped
    garden_size # This gives an ID that will need to be mapped
    garden_status # This gives an ID that will need to be mapped
    search_distance # This gives an ID that will need to be mapped
    interested_growing # String value that is json encoded, it needs to be decoded
    type # This gives an ID that will need to be mapped
    formatted_address
    street_name
    street_number
    city
    zip
    state
    country
    geom
    is_enabled
  }`;