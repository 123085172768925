import React from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { closeModal } from './modalActions'

const VideoModal = ({src}) => {
  const dispatch = useDispatch()

  return (
    <>
      <Modal
        size="lg" 
        show 
        centered
        className="modal-full-width-sm modal-video"
        onHide={() => dispatch(closeModal())}>
        <Modal.Header closeButton className="border-0 py-3">
          
        </Modal.Header>  
        <Modal.Body>
          <div className="responsive-iframe aspect-16x9">
            <iframe src={src} title="video"/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

// VideoModal.propTypes = {

// }

export default VideoModal
