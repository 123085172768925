import React from 'react'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import LoadingComponent from '../common/ui/LoadingComponent'
import { UserIsAuthenticated } from '../common/auth/authUtils'
import { AuthLayout, DefaultLayout } from '../layouts'
// route imports
import sandboxRoutes from '../sandbox/routes.js'
import locationRoutes from '../modules/location/routes.js'
import chatRoutes from '../modules/chat/routes.js'
import resourcesRoutes from '../modules/resources/routes.js'
import merchandiseRoutes from '../modules/merchandise/routes.js'
import profileRoutes from '../modules/profile/routes.js'
import settingsRoutes from '../modules/settings/routes.js'

const AsyncHomePage = loadable(() => import('../pages/Homepage'), {
  loading: <LoadingComponent />,
})

const AsyncPrivacyPage = loadable(() => import('../pages/PrivacyPolicy'), {
  loading: <LoadingComponent />,
})

const AsyncDonatePage = loadable(() => import('../pages/Donate'), {
  loading: <LoadingComponent />,
})

const protectedRoutes = [
  // {
  //   path: '/privacy-policy',
  //   component: AsyncPrivacyPage,
  // },
  ...locationRoutes,
  ...chatRoutes,
  ...resourcesRoutes,
  ...merchandiseRoutes,
  ...profileRoutes,
  ...settingsRoutes
]

const sandboxPages = [
  ...sandboxRoutes,
]

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <DefaultLayout>
            <Switch>
              <Route exact path="/" component={ AsyncHomePage } />
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path="/privacy-policy" exact>
          <DefaultLayout>
            <Switch>
              <Route exact path="/privacy-policy" component={ AsyncPrivacyPage } />
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path="/donate" exact>
          <DefaultLayout>
            <Switch>
              <Route exact path="/donate" component={ AsyncDonatePage } />
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path="/sandbox" exact>
            <Switch>
              { sandboxPages.map(({ path, component }, key) => (
                <Route exact path={ path } component={ component } key={ key } />
              )) }
            </Switch>
        </Route>
        <Route path="/(.+)" exact>
          <AuthLayout>
            <Switch>
              { protectedRoutes.map(({ path, component }, key) => (
                <Route exact path={ path } component={ UserIsAuthenticated(component) } key={ key } />
              )) }
            </Switch>
          </AuthLayout>
        </Route>

      </Switch>
    </>
  )
}

export default Routes
