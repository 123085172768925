import { USER_AUTH_SIGN_IN, USER_AUTH_SIGN_OUT, LOAD_SESSION_USER } from './authConstants'
import { createReducer } from '../utils/reducerUtil';

const initialState = null

export function userSessionState(state = {}, payload) {
  switch (payload.type) {
    case LOAD_SESSION_USER:
    case USER_AUTH_SIGN_IN:
      return {
        ...state,
        ...payload.data,
      }
      case USER_AUTH_SIGN_OUT:
        return payload.data // this should be an empty object, no need for previous state
    default:
      return state;
  }
}

export default createReducer(initialState, {
  [ USER_AUTH_SIGN_IN ]: userSessionState,
  [ LOAD_SESSION_USER ]: userSessionState,
  [ USER_AUTH_SIGN_OUT ]: userSessionState,
})