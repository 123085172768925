import React from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TestNotification from './TestNotification'
import ProfileNotification from './ProfileNotification'

const notificationLookup = {
  TestNotification,
  ProfileNotification
}

const NotificationWrapper = () => {
  const currentNotification = useSelector(state => state.notifications)

  if (currentNotification) {
    const { notificationType, notificationProps } = currentNotification
    const NotificationComponent = notificationLookup[notificationType]
    return <NotificationComponent {...notificationProps} />
  }
  return <></>
}

NotificationWrapper.propTypes = {

}

export default NotificationWrapper
