// This file was moved, to view full history use git log --follow
import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../../common/ui/LoadingComponent'

const AsyncLocationDashboard = loadable(() => import('./LocationDashboard'), {
  loading: <LoadingComponent />
})

const clientRoutes = [
  {
    path: '/map',
    component: AsyncLocationDashboard
  },
  {
    path: '/list',
    component: AsyncLocationDashboard
  }
]

export default clientRoutes
