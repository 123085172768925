import React from "react";
// import PropTypes from 'prop-types'

const Header = ({ heading, children, className }) => {
  return (
    <>
      <header className={`bg-primary ${className}`}>
        <div className="pageBanner text-white">
          <div className="container">
            <h1>{heading}</h1>
          </div>
        </div>
        {children}
      </header>
    </>
  );
};

Header.propTypes = {};

export default Header;
