import { Auth } from 'aws-amplify'
import CryptoJS from 'crypto-js'
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart
} from '../async/asyncActions'
import { removeConversations } from '../conversations/conversationActions'
import { loadProfile, removeProfile } from '../currentUser/profileActions'
import { getAllLocations, removeLocations } from '../locations/locationsAction'
import { history } from '../utils/history'
import {
  USER_AUTH_SIGN_IN,
  USER_AUTH_SIGN_OUT,
  LOAD_SESSION_USER,
  FORGOT_PASSWORD
} from './authConstants'

export const userAuthSignIn = ({ username, password }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())

      // sign user in
      const currentAuthenticatedAwsUser = await Auth.signIn({
        username: username.trim(),
        password: password.trim()
      })

      dispatch({
        type: USER_AUTH_SIGN_IN,
        payload: {
          type: USER_AUTH_SIGN_IN,
          data: { ...currentAuthenticatedAwsUser }
        }
      })

      dispatch(await loadProfile(currentAuthenticatedAwsUser))
      dispatch(await getAllLocations())

      dispatch(asyncActionFinish())
    } catch (error) {
      if (error.code === 'PasswordResetRequiredException') {
        // Send confirmation code to user's email
        await dispatch(authForgotPasswordGetCode(username))
        dispatch(asyncActionFinish())
        throw error // throwing this error for the modal to pick it up
      } else {
        dispatch(asyncActionError())
        throw error
      }
    }
  }
}

export const authForgotPasswordGetCode = (username) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())

      await Auth.forgotPassword(username.trim())

      dispatch({
        type: FORGOT_PASSWORD,
        payload: { type: FORGOT_PASSWORD, payload: null }
      })

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      throw error
    }
  }
}

export const authForgotPasswordReset = ({ username, code, password }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())

      await Auth.forgotPasswordSubmit(
        username.trim(),
        code.trim(),
        password.trim()
      )

      dispatch({
        type: FORGOT_PASSWORD, // Reusing action, replace if need
        payload: { type: FORGOT_PASSWORD, payload: null }
      })

      await dispatch(userAuthSignIn({ username, password }))

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      throw error
    }
  }
}

export const userAuthSocialSignIn = ({ provider }) => {
  Auth.federatedSignIn({ provider })
}

export const userAuthSignOut = () => {
  return async (dispatch, getState) => {
    Auth.signOut()

    dispatch({
      type: USER_AUTH_SIGN_OUT,
      payload: {
        type: USER_AUTH_SIGN_OUT,
        data: null
      }
    })

    dispatch(removeProfile())
    dispatch(removeLocations())
    dispatch(removeConversations())
  }
}

export const authUserSignUp = ({ username, password }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const encryptedUserCreds = CryptoJS.AES.encrypt(
        JSON.stringify({ password: password.trim() }),
        process.env.REACT_APP_PASSWORD_SALT
      ).toString()
      localStorage.setItem('coginitoAuth', encryptedUserCreds)

      const newAuthenticatedAwsUser = await Auth.signUp({
        username: username.trim(),
        password: password.trim()
      })
      dispatch({
        type: USER_AUTH_SIGN_IN,
        payload: {
          type: USER_AUTH_SIGN_IN,
          data: { ...newAuthenticatedAwsUser }
        }
      })

      await dispatch(
        userAuthSignIn({
          username,
          password
        })
      )

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      throw error
    }
  }
}

export const authPasswordReset = ({ username, password }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const encryptedUserCreds = CryptoJS.AES.encrypt(
        JSON.stringify({ password }),
        process.env.REACT_APP_PASSWORD_SALT
      ).toString()
      localStorage.setItem('coginitoAuth', encryptedUserCreds)

      const newAuthenticatedAwsUser = await Auth.completeNewPassword({
        username: username.trim(),
        password: password.trim()
      })

      dispatch({
        type: USER_AUTH_SIGN_IN,
        payload: {
          type: USER_AUTH_SIGN_IN,
          data: { ...newAuthenticatedAwsUser }
        }
      })

      // dispatch(userAuthSignIn({
      //   username,
      //   password
      // }))

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      throw error
    }
  }
}

export const authUserValidationCode = ({ username, code }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      await Auth.confirmSignUp(username.trim(), code.trim(), {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true
      })

      const cognitoAuth = localStorage.getItem('coginitoAuth')
      localStorage.removeItem('coginitoAuth')
      const bytes = CryptoJS.AES.decrypt(
        cognitoAuth,
        process.env.REACT_APP_PASSWORD_SALT
      )

      const { password } = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

      await dispatch(userAuthSignIn({ username, password }))
      // user successfully auth'd, they now need to login.

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      throw error
    }
  }
}

export const validateSession = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      const currentAuthenticatedAwsUser = await Auth.currentAuthenticatedUser()
      dispatch({
        type: LOAD_SESSION_USER,
        payload: {
          type: LOAD_SESSION_USER,
          data: { ...currentAuthenticatedAwsUser }
        }
      })

      dispatch(await loadProfile(currentAuthenticatedAwsUser))
      dispatch(await getAllLocations())

      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionFinish())
      // nulify error, no user is loaded
      // console.log("No user in session:", err)
      const currentPath = history.location.pathname
      if (currentPath != '/privacy-policy' && currentPath != '/donate') {
        history.push('/')
      }
    }
  }
}
