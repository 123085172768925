import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../common/ui/LoadingComponent'

const AsyncSandbox = loadable(() => import('./pages/Sandbox'), { loading: <LoadingComponent /> })
const AsyncSandboxAuth = loadable(() => import('./pages/Auth'), { loading: <LoadingComponent /> })

const sandboxRoutes = [
  {
    path: '/sandbox',
    component: AsyncSandbox
  },
  {
    path: '/sandbox/auth',
    component: AsyncSandboxAuth
  },
]

export default sandboxRoutes
