import { toastr } from 'react-redux-toastr'
import { graphqlClient } from '../../graphql/graphqlClient';
import { GQL_CREATE_MESSAGE, GQL_UPDATE_CONVERSATION_LINK_LAST_READ } from '../../graphql/hasura/messages/mutations';
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import { CREATE_MESSAGE, HIDE_CONVERSATION_WINDOW, REMOVE_CONVERSATIONS, SET_ACTIVE_CONVERSATION } from './conversationConstants'

export const setActiveConversation = (conversation_link) => {
  return async (dispatch, getState) => {
    // dispatch(asyncActionStart())

    // update the last read timestamp on the conversation_link
    const gqlClient = await graphqlClient()
    const { update_conversation_link: {returning} } = await gqlClient.request(GQL_UPDATE_CONVERSATION_LINK_LAST_READ, { id: conversation_link.id, last_read_date: JSON.stringify(new Date()) })

    conversation_link.last_read_date = returning[0].last_read_date

    dispatch({
      type: SET_ACTIVE_CONVERSATION,
      payload: {
        type: SET_ACTIVE_CONVERSATION,
        data: conversation_link
      }
    })
    // dispatch(asyncActionFinish())
  }
}

export const hideConversationWindow = (shouldHide) => {
  return {
    type: HIDE_CONVERSATION_WINDOW,
    payload: {
      type: HIDE_CONVERSATION_WINDOW,
      data: shouldHide
    }
  }
}

export const createMessage = ({user_id, conversation_id, content}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      const gqlClient = await graphqlClient()
      const { insert_messages: { returning } } = await gqlClient.request(GQL_CREATE_MESSAGE, { user_id, conversation_id, content })

      const newMessage = returning[ 0 ]

      dispatch({
        type: CREATE_MESSAGE,
        payload: {
          type: CREATE_MESSAGE,
          data: {}
        }
      })

      dispatch(asyncActionFinish())
      
      return newMessage
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError());
      toastr.error('Oops!', 'Could create a new message')
      throw new Error("Could not create a new message")
    }
  }
}

export const removeConversations = () => {
  return {
    type: REMOVE_CONVERSATIONS,
    payload: {
      type: REMOVE_CONVERSATIONS,
      data: {}
    }
  }
}