import PropTypes from "prop-types";

const LocationIcon = ({ type, className, onClick }) => {
  return (
    <>
      <span
        onClick={onClick}
        className={`icon-location icon-${type} ${className}`}
      />
    </>
  );
};

LocationIcon.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.instanceOf(Function),
};
LocationIcon.defaultProps = {
  className: "",
  onClick: () => {},
};

export default LocationIcon;
