import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA4 from 'react-ga4'
import { openModal } from '../common/modals/modalActions'
import logoSE from '../common/assets/shared-earth-horizontal.svg'
import logoSA from '../common/assets/images/SA_presented_by.png'
import { history } from '../common/utils/history'
import MobileHomeNav from './MobileHomeNav'
import { isUIWebView } from '../common/utils/helpers'

const HomeNav = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) =>
    state.currentUser.auth && state.currentUser.profile ? true : false
  )

  return (
    <>
      <header id='homeHeader'>
        <div className='d-none d-lg-block'>
          <div className='container'>
            <Navbar collapseOnSelect expand='lg'>
              <Navbar.Brand href='/'>
                <img
                  src={logoSE}
                  className='img-fluid'
                  alt='Shared Earth logo'
                />
              </Navbar.Brand>
              <Navbar.Collapse id='mainnav'>
                <ul className='navbar-nav ml-auto align-items-center'>
                  <li className='nav-item'>
                    <a href='/#how-it-works' className='nav-link '>
                      How it works
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      href={process.env.REACT_APP_SHOPIFY_URL}
                      className='nav-link'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='icon-custom icon-merchandise d-inline-block d-lg-none' />
                      Merchandise
                    </a>
                  </li>
                  <li className='nav-item'>
                    <button
                      onClick={() => {
                        ReactGA4.event({
                          category: 'Manual Donation Modal Event',
                          action: 'Donation Modal Open'
                        })
                        if (!isUIWebView) {
                          history.push('?showDonationModal=true')
                          dispatch(openModal('DonateModal'))
                        } else {
                          const a = document.createElement('a')
                          a.setAttribute(
                            'href',
                            'https://sharedearth.com/donate'
                          )
                          a.setAttribute('target', '_blank')

                          const dispatch = document.createEvent('HTMLEvents')
                          dispatch.initEvent('click', true, true)
                          a.dispatchEvent(dispatch)
                        }
                      }}
                      className='nav-link bg-transparent'
                    >
                      Donate
                    </button>
                  </li>
                  {!isLoggedIn ? (
                    <>
                      <li className='nav-item position-relative'>
                        <button
                          className='btn btn-outline-blue-light'
                          onClick={() => {
                            dispatch(openModal('LoginModal'))
                            history.push('?showLoginModal=true')
                          }}
                        >
                          Login
                        </button>
                      </li>
                      <li className='nav-item position-relative'>
                        <button
                          className='btn btn-outline-blue-light'
                          onClick={() => {
                            dispatch(openModal('SignupModal'))
                            history.push('?showSignupModal=true')
                          }}
                        >
                          Sign Up
                        </button>
                      </li>
                    </>
                  ) : (
                    <li className='nav-item position-relative'>
                      <button
                        className='btn btn-outline-blue-light'
                        onClick={() => {
                          history.push('/map')
                        }}
                      >
                        Goto App
                      </button>
                    </li>
                  )}

                  <li className='nav-item nav-sa-logo'>
                    <a
                      href='https://sustainableamerica.org/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={logoSA}
                        className='img-fluid'
                        alt='Sustainable America logo'
                      />
                    </a>
                  </li>
                </ul>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <MobileHomeNav />
      </header>
    </>
  )
}

export default HomeNav
