import { gql } from 'graphql-request'
import { GQL_FRAG_IMAGE_FIELDS } from '../images/fragments';
import { GQL_FRAG_LOCATION_FIELDS } from './fragments';

export const GQL_CREATE_LOCATION = gql`
  ${GQL_FRAG_LOCATION_FIELDS}
  ${GQL_FRAG_IMAGE_FIELDS}
  mutation createLocation($objects: [locations_location_insert_input!] = {}) {
  insert_locations_location(objects: $objects) {
    returning {
      ...LocationFields
        images {
          ...ImageFields
        }
    }
  }
}`;

export const GQL_UPDATE_LOCATION = gql`
   ${GQL_FRAG_LOCATION_FIELDS}
   ${GQL_FRAG_IMAGE_FIELDS}
  mutation UpdateLocation($id:Int!, $changes: locations_location_set_input) {
    update_locations_location(where: {id: {_eq: $id}}, _set: $changes) {
      returning {
        ...LocationFields
          images {
            ...ImageFields
          }
      }
    }
  }`;



