import { createReducer } from '../utils/reducerUtil'
import {
  TOGGLE_SIDEBAR,
  TOGGLE_LIST_VIEW
} from './uiConstants'


const initialState = {
  isSidebarOpen: true,
  isListView: true,
}

export const toggleSidebar = (state, payload) => {
  const { isSidebarOpen } = payload
  return {
    ...state,
    isSidebarOpen
  }
}

export const toggleListView = (state, payload) => {
  const { isListView } = payload
  return {
    ...state,
    isListView
  }
}

export default createReducer(initialState, {
  [ TOGGLE_SIDEBAR ]: toggleSidebar,
  [ TOGGLE_LIST_VIEW ]: toggleListView
})
