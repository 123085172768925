import { Storage } from "aws-amplify"
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import { updateListingImages } from '../currentUser/profileActions'



/**
 * https://d2d3dubs66kcm2.cloudfront.net/index.html
 * 
 * CDN S3 URL: https://sharedearth104600-sedev.s3.us-west-2.amazonaws.com/shared-earth-vertical.png
 * bucket: sharedearth104600-sedev
 * Key: shared-earth-vertical.png
 */

/**
 * This utility will help determine which bucket to use. We have images stored
 * in the db that are from the old bucket and images that are from the new bucket.
 *
*/
const legacyHostName = 'sharedearth-prod.s3.amazonaws.com'
const bucket = process.env.REACT_APP_S3_BUCKET;
const legacyBucket = process.env.REACT_APP_S3_BUCKET_LEGACY;
const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_IMAGE_URL;


const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * The util will work by passing an image path in, and getting an object of the image path and bucket.
 * For example:
 *
 * https://sharedearth-prod.s3.amazonaws.com/m/f3pk2V4vPk2XpL7YKd8otB/wP8sXxJHSrVvLSL2WX2LKJ.240.jpeg
 * will return:
 * {
 *    bucket: 'sharedearth-prod'
 *    path: 'm/f3pk2V4vPk2XpL7YKd8otB/wP8sXxJHSrVvLSL2WX2LKJ.240.jpeg
 * }
 *
 * @param {String} imageUrl
 * @returns object with image properties
 */
export const getImageProperties = (imageUrl) => {
  const imageProperties = {
    bucket: null,
    path: null
  }

  // Back out if there is no image url
  if (!imageUrl) return imageProperties

  if (!isValidHttpUrl(imageUrl)) {
    // just the image path here, use the new bucket
    imageProperties.bucket = bucket
    imageProperties.path = `public/${imageUrl}`

    return imageProperties
  }

  const { host, pathname } = new URL(imageUrl)
  if (host === legacyHostName) {
    imageProperties.bucket = legacyBucket
    imageProperties.path = pathname.replace(/\//, "") // remove the first forward slash

    return imageProperties
  }

  return imageProperties
}

/**
 *
 * @param {string} imagePath
 * @param {object} edits
 * @returns {string} cloudfront url
 */
export const getCloudFrontImage = (imagePath, edits) => {
  if (isValidHttpUrl(imagePath)) {
    return imagePath
  }

  const { bucket, path } = getImageProperties(imagePath) // get the bucket and path
  if (!bucket || !path) return null // if no properties found, just return null

  const imageRequest = JSON.stringify({
    bucket,
    key: path,
    edits,
  });

  return `${cloudFrontUrl}/${btoa(imageRequest)}`;
}


export const uploadImagesToS3 = async ({ dispatch, profile, location_id, images }) => {
  dispatch(asyncActionStart())
  let newImages = []
  try {

    for (const image of images) {
      // If we dont have an image file, back out we shouldn't do anything here
      if (image instanceof File) {
        let path = image.path

        path = `${profile.cognito_sub}/location/${location_id}/${image.path}`
        await Storage.put(path, image, {
          contentType: "image/*",
        })

        newImages.push({
          user_id: profile.id,
          location_id: location_id,
          path
        })
      }
    }

    // only make changes to redux with new locations
    if (newImages.length) {
      await dispatch(updateListingImages({ images: newImages, location_id }))
    }
    dispatch(asyncActionFinish())
    return newImages
  } catch (error) {
    dispatch(asyncActionError())
    console.log(error)
  }
}