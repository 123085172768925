import React from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Alert } from 'react-bootstrap'
import { closeNotification } from './notificationActions'


const TestNotification = ({ heading }) => {
  const dispatch = useDispatch()

  return (
    <>
      <Alert className="alert-global" variant="info" onClose={() => dispatch(closeNotification())} dismissible>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Alert.Heading>Uh Oh... Your profile is incomplete</Alert.Heading>
              <small>
                Change this and that and try again. Duis mollis, est non commodo
                luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                Cras mattis consectetur purus sit amet fermentum.
              </small>
            </div>
          </div>
        </div>
      </Alert>
    </>
  )
}

// TestNotification.propTypes = {

// }

export default TestNotification
