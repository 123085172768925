import React, { useState } from 'react'
import Label from "./label"
import LocationIcon from '../ui/LocationIcon'
import {typeOptions} from '../data/selects'

const ListingTypeInput = (props) => {
  const { children, className, field, label, hint, form: { setFieldValue, values } } = props
  const [editing, setEditing] = useState(true)

  return (
    <>
      {children && !editing ? (
        <div className="editable" onClick={() => setEditing(true)}>
          {children}
        </div>
      ) : (
        <div className={`form-group ${className}`}>
          <Label label={label} hint={hint} />
          <div className={`row`}>
            {typeOptions && typeOptions.map(option => {
              return (
                <div className="col-12 col-lg-6" key={`option_${option.value}`}>
                  <div
                    className={`listing-type ${option.value} ${option.value === values[field.name] ? 'selected' : ''}`}
                    onClick={() => setFieldValue(field.name, option.value)}
                  >
                    <div>
                      <LocationIcon type={option.value} className="icon-xl mr-2" />
                    </div>
                    <div className="text-left">
                      <span>{option.label}</span>
                      <p><small>{option.description}</small></p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default ListingTypeInput
