import { GraphQLClient } from 'graphql-request'
import { Auth } from 'aws-amplify';

const GRAPHQL_ENDPOINT = `https://${process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT}`

export const graphqlClient = () => {
  return new Promise(async (resolve, reject) => {
    let jwtToken
    let headers = {}
    try {
      const user = await Auth.currentSession()
      jwtToken = user.idToken.jwtToken
    } catch (error) {
      // silently kill error, we have no user.
    }

    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`
    }

    const client = new GraphQLClient(GRAPHQL_ENDPOINT, {
      headers,
    })


    resolve(client)
  })
}