import React, { useCallback } from "react";
// import PropTypes from 'prop-types'
import { useDropzone } from "react-dropzone";
import Label from "./label";
import FilePreview from "./FilePreview";

const FileUpload = ({
  hint,
  className,
  label,
  field: { name, value },
  storagePath = "uploads",
  maxSize = 8000000,
  multiple = false,
  form: { setFieldValue },
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      multiple && value
        ? setFieldValue(name, [...acceptedFiles, ...value])
        : setFieldValue(name, acceptedFiles[0]);
    },
    /* eslint-disable */
    [storagePath, multiple, name, value, setFieldValue]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple,
    minSize: 0,
    maxSize,
    onDrop,
  });

  const isFileTooLarge =
    fileRejections &&
    fileRejections.length > 0 &&
    fileRejections[0].file.size > maxSize;

  return (
    <>
      <Label label={label} hint={hint} />

      <div className={`form-group`}>
        <input className="form-control" {...getInputProps()} />
        <div
          {...getRootProps({
            className: `dropzone ${multiple ? "isMulti" : ""}`,
          })}
        >
          <div
            className={`
              ${isDragReject ? "file-upload bg-danger p-5" : "file-upload p-5"}
              ${!value ? "empty" : ""}
            `}
          >
            <div className="text-center">
              {!isDragActive && (
                <span>Click to browse</span>
              )}
              {isDragActive &&
                (isDragReject ? (
                  <span>
                    {multiple
                      ? "Sorry... only certain filetypes are accepted!"
                      : "Sorry... only one file please!"}
                  </span>
                ) : (
                  <span>Drop here ...</span>
                ))}
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  File too large, please try another under {maxSize / 1000000}{" "}
                  mb.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-hint">
          <p>{hint}</p>
        </div>
      </div>
      <div className={className}>
        {value &&
          multiple &&
          value.map((file) => {
            return (
              <FilePreview key={file.path} file={file} className="card p-3">
                <i
                  className="far fa-times-circle"
                  onClick={() =>
                    setFieldValue(
                      name,
                      value.filter((value) => value.path !== file.path)
                    )
                  }
                />
              </FilePreview>
            );
          })}

        {value && !multiple && (
          <FilePreview
            file={value.path ? value : { id: "default", path: value }}
            className="card card-preview"
          >
            <i className="far fa-pen-square" onClick={() => set} />
          </FilePreview>
        )}
      </div>
    </>
  );
};

// FileUpload.propTypes = {

// }

export default FileUpload;
