import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import Slider from "react-slide-out"
import { closeDrawer } from "./drawerActions"
import ProfileDrawer from './ProfileDrawer'
import ListingDrawer from './ListingDrawer'
import BlockedUserDrawer from './BlockedUserDrawer'

const drawerLookup = {
  ProfileDrawer,
  ListingDrawer,
  BlockedUserDrawer,
};

const DrawerWrapper = () => {
  const dispatch = useDispatch()
  const pathName = useSelector((state) => state.router.location.pathname);
  const currentDrawer = useSelector((state) => state.drawers);

  useEffect(() => {
    dispatch(closeDrawer())
  }, [pathName, dispatch])

  const close = () => {
    // history.push(pathName)  // clear the path name
    dispatch(closeDrawer())
  }

  if (currentDrawer) {
    const { drawerType, drawerProps } = currentDrawer
    const DrawerComponent = drawerLookup[drawerType]
    return (

      <Slider isOpen={true} onOutsideClick={() => close()}>
        <button
          onClick={() => close()}
          className="close">
            <i className="fas fa-chevron-left mr-2"></i>
            Back
        </button>
        <DrawerComponent {...drawerProps} />
      </Slider>

    );
  }
  return <></>
};

DrawerWrapper.propTypes = {}

export default DrawerWrapper
