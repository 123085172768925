import { graphqlClient } from '../../graphql/graphqlClient'
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import { GET_LOCATIONS, SET_ACTIVE_LOCATION, SET_USER_TYPE_FILTER, REMOVE_LOCATIONS, GET_ALL_GEO_LOCATIONS } from './locationsConstant';
import { GQL_LOAD_LOCATIONS, GQL_GET_LOCATIONS_FROM_BOUNDS, GQL_GET_PUBLIC_LOCATIONS_BY_LOCATION } from '../../graphql/hasura/locations/queries';

export const getAllLocations = () => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const { locations_location } = await gqlClient.request(GQL_LOAD_LOCATIONS)

      dispatch({
        type: GET_ALL_GEO_LOCATIONS,
        payload: { type: GET_ALL_GEO_LOCATIONS, data: locations_location }
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}

export const getPublicLocationsFromLocation = ({ distance, location }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const { get_locations_around_location } = await gqlClient.request(GQL_GET_PUBLIC_LOCATIONS_BY_LOCATION, { distance, location })
      dispatch({
        type: GET_LOCATIONS,
        payload: { type: GET_LOCATIONS, data: [] }
      })

      dispatch(asyncActionFinish())

      return {
        need: get_locations_around_location.filter(loc => loc.type === "need").length,
        have: get_locations_around_location.filter(loc => loc.type === "have").length
      }

    } catch (error) {
      dispatch(asyncActionError());
      console.log(error)
    }
  }
}


export const getLocationsFromBounds = ({ lngW, latS, lngE, latN }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    const gqlClient = await graphqlClient()
    try {
      const { get_locations_from_bounds } = await gqlClient.request(GQL_GET_LOCATIONS_FROM_BOUNDS, { lngW, latS, lngE, latN })

      dispatch({
        type: GET_LOCATIONS,
        payload: { type: GET_LOCATIONS, data: get_locations_from_bounds }
      })

      dispatch(asyncActionFinish())
      return get_locations_from_bounds

    } catch (error) {
      dispatch(asyncActionError());
      console.log(error)
    }
  }
}

export const setActiveLocation = (location) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      // set the active location
      dispatch({
        type: SET_ACTIVE_LOCATION,
        payload: {
          type: SET_ACTIVE_LOCATION,
          data: location
        }
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError());
    }
  }
}


export const setUserTypeFilter = (filterType) => {
  return {
    type: SET_USER_TYPE_FILTER,
    payload: {
      type: SET_USER_TYPE_FILTER,
      data: filterType
    }
  }
}

export const removeLocations = () => {
  return {
    type: REMOVE_LOCATIONS,
    payload: {
      type: REMOVE_LOCATIONS,
      data: {}
    }
  }
}