import React, { useEffect, useState } from 'react'
import Header from '../common/ui/Header'
import { useSelector } from 'react-redux'
import SignoutButton from '../common/auth/SignoutButton'

const InactvieAccountDashboard = () => {
  const { profile } = useSelector((state) => state.currentUser)
  const [daysLeft, setDaysLeft] = useState(null)

  useEffect(() => {
    if (profile?.archived_date) {
      // Parse the archived date
      const archived = new Date(profile.archived_date)
      // Get the current date
      const current = new Date()

      // Calculate the difference in milliseconds
      const diffInMs = Math.abs(archived - current)
      // Convert to days and round down
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

      // Given that the policy is 30 days
      let daysBeforeDeletion = 30 - diffInDays
      daysBeforeDeletion = daysBeforeDeletion > 0 ? daysBeforeDeletion : 0

      setDaysLeft(daysBeforeDeletion)
    }
  }, [profile])

  const handleUnarchive = async () => {
    try {
      const response = await fetch(
        'https://sharedearth.hasura.app/api/rest/unarchive',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ACCESS_KEY
          },
          body: JSON.stringify({ _csub: profile.cognito_sub })
        }
      )

      if (!response.ok) {
        throw new Error('Something went wrong with the PUT request.')
      }

      setTimeout(function () {
        window.location.reload()
      }, 950)
    } catch (error) {
      console.error('Error making PUT request:', error)
    }
  }

  return (
    <>
      <Header heading='Account Inactive' />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <p className="large-text">
                Your account is inactive and all data will be deleted in{' '}
                {daysLeft} day(s).
              </p>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-12 text-center'>
              <button
                className='btn btn-outline-danger mx-auto d-block w-25'
                onClick={handleUnarchive}
              >
                Restore
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 text-center'>
              <SignoutButton />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default InactvieAccountDashboard
