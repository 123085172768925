export const typeOptions = [
  {
    label: 'I am a Gardener',
    value: 'need',
    description: 'Seeking Garden Space'
  },
  {
    label: 'I have Land to Share',
    value: 'have',
    description: 'Offering My Lawn or Garden'
  }
]

// Maps to locations.distance
export const searchDistanceOptions = [
  {
    label: "0.5 miles",
    value: 0.5
  },
  {
    label: "1 mile",
    value: 1
  },
  {
    label: "2 miles",
    value: 2
  },
  {
    label: "5 miles",
    value: 5
  },
  {
    label: "10 miles",
    value: 10
  },
  {
    label: "20 miles",
    value: 20
  },
  {
    label: "50 miles",
    value: 50
  }
]

// Maps to profile.experience
// TODO: Make call for this to be locations.experience
//   - Need to migrate data
export const experienceOptions = [
  {
    label: "New to gardening",
    value: 0.5
  },
  {
    label: "1 year",
    value: 1
  },
  {
    label: "2-5 years",
    value: 2
  },
  {
    label: "5-10 years",
    value: 5
  },
  {
    label: "10+ years",
    value: 10
  }
]

// Maps to profile.responsibilities
// TODO: Make call for this to be locations.responsibilities
//   - Need to migrate data
export const responsibilitiesOptions = [
  {
    label: "I'd like to work alone",
    value: 1
  },
  {
    label: "We could work together if you'd like",
    value: 2
  },
  {
    label: "I'm going to need some help",
    value: 3
  }
]

// Maps to locations.garden_size
export const gardenSizeOptions = [
  {
    label: "< 50 sq ft",
    value: 1
  },
  {
    label: "50 - 250 sq ft",
    value: 2
  },
  {
    label: "250 - 500 sq ft",
    value: 3
  },
  {
    label: "500 - 1000 sq ft",
    value: 4
  },
  {
    label: "1000 - 1500 sq ft",
    value: 5
  },
  {
    label: "1 acre",
    value: 6
  },
  {
    label: "5 acres",
    value: 7
  },
  {
    label: "10 acres",
    value: 8
  },
  {
    label: "20 acres",
    value: 9
  },
  {
    label: "50 acres",
    value: 10
  },
  {
    label: "100+ acres",
    value: 11
  }
]

// Maps to locations.compensation
export const compensationOptions = [
  {
    label: "Free to use",
    value: 1
  },
  {
    label: "Rent for money",
    value: 2
  },
  {
    label: "Share your harvest",
    value: 3
  },
  {
    label: "Other arrangement",
    value: 4
  }
]

// Maps to locations.garden_status
export const gardenStatusOptions = [
  {
    label: "Ready to plant",
    value: 1
  },
  {
    label: "Needs some work",
    value: 2
  },
  {
    label: "Isn't a garden yet",
    value: 3
  },
]

export const interestedGrowingOptions = [
  {
    label: "Vegetables",
    value: 1
  },
  {
    label: "Fruit",
    value: 2
  },
  {
    label: "Herbs",
    value: 3
  },
]

