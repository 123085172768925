import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../../common/ui/LoadingComponent'

const AsyncMerchandiseDashboard = loadable(() => import('./MerchandiseDashboard'), {
  loading: <LoadingComponent />
})

const clientRoutes = [
  {
    path: '/merchandise',
    component: AsyncMerchandiseDashboard
  }
]

export default clientRoutes
