import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  concat,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import Auth from "@aws-amplify/auth";

const GRAPHQL_ENDPOINT = `https://${process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT}`;
const WEBSOCKET_ENDPOINT = `wss://${process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT}`;

export const createApolloClient = () => {

  const authHeader = setContext(
    request =>
      new Promise((success, fail) => {
        Auth.currentSession().then(token => success({ headers: { authorization: `Bearer ${token}` } }))
      })
  )

  const wsLink = new WebSocketLink({
    uri: WEBSOCKET_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: async () => {
        const { idToken: { jwtToken } } = await Auth.currentSession()
        return {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          }
        }
      }
    },
  });

  const httpLink = new HttpLink({
    uri: GRAPHQL_ENDPOINT,
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    concat(authHeader, httpLink)
  );

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      addTypename: false,
    })
  })
}