import React, { useEffect, useState } from "react";
import autosize from "autosize";

const MessageInput = (props) => {
  const {
    children,
    className,
    type,
    field,
    onFocus,
    onBlur,
    placeholder,
    form,
  } = props;

  const{ errors, touched} = form

  const textArea = document.querySelectorAll("textarea");
  autosize(textArea);

  useEffect(() => {
    if (field.value === "") {
      autosize.update(textArea);
    }
    /* eslint-disable */
  }, [field.value]);


  const status = touched[field.name] && errors[field.name] ? "is-invalid" : "";
  const [editing, setEditing] = useState(false);
  
  const style = {
    maxHeight: "120px",
    minHeight: "38px",
    resize: "none",
    padding: "9px",
    boxSizing: "border-box",
    fontSize: "16px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "0.75rem",
    borderColor: "#ced4da"
  };

  return (
    <>
      {children && !editing ? (
        <div className="editable" onClick={() => setEditing(true)}>
          {children}
        </div>
      ) : (
        <div className={`form-group mb-0 w-100 ${className}`}>
          <textarea
            placeholder={placeholder}
            style={style}
            type={type}
            rows={1}
            onFocus={onFocus}
            name={field.name}
            {...field}
            onBlur={onBlur}
          />
          {touched[field.name] && errors[field.name] && (
            <div className="invalid-feedback">{errors[field.name]}</div>
          )}
        </div>
      )}
    </>
  );
};

export default MessageInput;
