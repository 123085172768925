import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCloudFrontImage } from "../utils/s3bucketHelper";
import userIcon from '../assets/icons/shared-earth-profile-icon-light.svg'

const useProfileImage = ({ width, height }) => {
  const { profile } = useSelector((state) => state.currentUser);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    if (profile && profile.avatar_url) {
      const profileImageStorageUrl = getCloudFrontImage(profile.avatar_url, {
        resize: {
          width,
          height,
          fit: "contain",
        },
      }) || userIcon;
      setProfileImage(profileImageStorageUrl);
    }
    /* eslint-disable */
  }, [profile]);

  return profileImage;
};

export default useProfileImage;
