import React from 'react'
import { Dimmer } from 'semantic-ui-react'
import {useSelector} from 'react-redux'
import LoadingIndicator from './LoadingIndicator'

const LoadingComponent = ({ inverted, content="Loading...", forceLoading=false }) => {
  const loading = useSelector(state => state.async.loading)

  if(loading || forceLoading) {
    return (
      <Dimmer inverted={inverted} active={true}>
        <LoadingIndicator message={content} />
      </Dimmer>
    )
  }

  return <></>
}

export default LoadingComponent