import React from "react";

const ErrorBanner = ({ errorMessage }) => {
  return (
    <>
      {errorMessage && (
        <div className="alert alert-info m-0" role="alert">
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default ErrorBanner;
