import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../../common/ui/LoadingComponent'

const AsyncSettingsDashboard = loadable(() => import('./SettingsDashboard'), {
  loading: <LoadingComponent />
})

const clientRoutes = [
  {
    path: '/settings',
    component: AsyncSettingsDashboard
  }
]

export default clientRoutes
