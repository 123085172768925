import { gql } from 'graphql-request'
import { GQL_FRAG_IMAGE_FIELDS } from './fragments';

export const GQL_ADD_IMAGES = gql`
  ${GQL_FRAG_IMAGE_FIELDS}
  mutation addImages($objects: [images_image_insert_input!] = {}) {
    insert_images_image(objects: $objects) {
    returning {
      ...ImageFields
    }
  }
}`;

export const GQL_REMOVE_IMAGE = gql`
  ${GQL_FRAG_IMAGE_FIELDS}
  mutation removeImages($id: Int!) {
  delete_images_image(where: {id: {_eq: $id}}) {
    returning {
      ...ImageFields
    }
  }
}`;


export const GQL_REMOVE_LISTING_IMAGES = gql`
  mutation DeleteListingImages($location_id:Int!) {
    delete_images_image(where: { location_id: { _eq: $location_id } }) {
      affected_rows
    }
  }`