import { NOTIFICATION_CLOSE, NOTIFICATION_OPEN } from './notificationConstants'
import { createReducer } from '../utils/reducerUtil'

const initialState = null

export const openNotification = (state, payload) => {
  const { notificationType, notificationProps } = payload
  return { notificationType, notificationProps }
}

export const closeNotification = (state, payload) => {
  return null
}

export default createReducer(initialState, {
  [NOTIFICATION_OPEN]: openNotification,
  [NOTIFICATION_CLOSE]: closeNotification
})