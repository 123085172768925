import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ReactGA4 from 'react-ga4'
// import AuthNav from '../common/auth/AuthNav'
import logo from '../common/assets/shared-earth-vertical-white.svg'
import sponsor from '../common/assets/SA_presented_by_white.png'
import useProfileImage from '../common/currentUser/useProfileImage'
// import iconMap from '../common/assets/icons/icon-map.svg'
import { openModal } from '../common/modals/modalActions'
import userIcon from '../common/assets/icons/shared-earth-profile-icon-light.svg'
import { history } from '../common/utils/history'
import { isUIWebView } from '../common/utils/helpers'

const SideNav = () => {
  const dispatch = useDispatch()
  const profileImage = useProfileImage({ width: 100, height: 100 }) || userIcon

  return (
    <>
      <div className='primaryMenu'>
        <div>
          <nav id='primaryNav'>
            <NavLink to='/' className='nav-logo d-flex p-4'>
              <img
                className='mx-auto img-fluid'
                src={logo}
                alt='shared-earth'
              />
            </NavLink>
            <NavLink to='/profile' className='d-flex my-2 my-lg-5 px-2'>
              <div className='avatar-wrapper mx-auto'>
                <div
                  style={
                    profileImage && { backgroundImage: `url(${profileImage})` }
                  }
                  className='avatar bg-light'
                />
              </div>
            </NavLink>
            <NavLink to='/map' className='nav-link'>
              <i className='icon-custom icon-map-white' />
              <span>Map</span>
            </NavLink>
            <NavLink to='/list' className='nav-link'>
              <i className='icon-custom icon-list-white' />
              <span>List</span>
            </NavLink>
            <NavLink to='/messages' className='nav-link'>
              <i className='icon-custom icon-messages-white' />
              <span>Messages</span>
            </NavLink>
            <NavLink to='/resources' className='nav-link'>
              <i className='icon-custom icon-resources-white' />
              <span>Resources</span>
            </NavLink>
            <button
              onClick={() => {
                ReactGA4.event({
                  category: 'Manual Donation Modal Event',
                  action: 'Donation Modal Open'
                })
                if (!isUIWebView) {
                  history.push('?showDonationModal=true')
                  dispatch(openModal('DonateModal'))
                } else {
                  const a = document.createElement('a')
                  a.setAttribute('href', 'https://sharedearth.com/donate')
                  a.setAttribute('target', '_blank')

                  const dispatch = document.createEvent('HTMLEvents')
                  dispatch.initEvent('click', true, true)
                  a.dispatchEvent(dispatch)
                }
              }}
              className='nav-link'
            >
              <i className='icon-custom icon-donate-white' />
              <span>Donate</span>
            </button>
            <a
              href={process.env.REACT_APP_SHOPIFY_URL}
              className='nav-link'
              target='_blank'
              rel='noreferrer'
            >
              <i className='icon-custom icon-merchandise-white' />
              <span>Merchandise</span>
            </a>
            <hr className='border-white w-50 mx-auto' />
          </nav>
          <nav id='userNav'>
            <NavLink to='/profile' className='nav-link'>
              <i className='icon-custom icon-profile-white' />
              <span>My Profile</span>
            </NavLink>
            <NavLink to='/settings' className='nav-link'>
              <i className='icon-custom icon-settings-white' />
              <span>Settings</span>
            </NavLink>
          </nav>
        </div>
        <div className='d-flex p-5 footer-logo-wrapper'>
          <a
            href='https://sustainableamerica.org/'
            rel='noreferrer'
            target='_blank'
          >
            <img
              className='mx-auto nav-footer-logo img-fluid'
              src={sponsor}
              alt='shared-earth'
            />
          </a>
        </div>
        {/* <AuthNav /> */}
      </div>
    </>
  )
}

// SideNav.propTypes = {

// }

export default SideNav
