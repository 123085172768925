const authErrors = [
  {
    code: 'AccessDeniedException',
    message: 'You do not have sufficient access to perform this action.'
  },
  {
    code: 'InvalidPasswordException',
    message: 'Password does not meet the requirements'
  },
  {
    code: 'NotAuthorizedException',
    message: 'Incorrect username or password.'
  },
  {
    code: 'UserNotFoundException',
    message: 'Username/client id combination not found.'
  },
  {
    code: 'RequestExpired',
    message: 'The request reached the service more than 15 minutes after the date stamp on the request or more than 15 minutes after the request expiration date (such as for pre-signed URLs), or the date stamp on the request is more than 15 minutes in the future.'
  },
  {
    code: 'UsernameExistsException',
    message: 'An account with the given email already exists.'
  },
  {
    code: 'PasswordMissMatch',
    message: 'Your passwords do not match'
  }
]

export const getAuthCodeError = (errorCode) => {
  const error = authErrors.find(err => err.code === errorCode)
  return ('message' in error) ? error.message : 'Opps, there was a problem'
}