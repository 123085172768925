import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import { Modal } from 'react-bootstrap'
import { closeModal } from '../modalActions'
import logoSE from '../../../common/assets/shared-earth-vertical.svg'
import { TextInput } from '../../fields'
import { userAuthSignIn, userAuthSocialSignIn } from '../../auth/authActions'
import { history } from '../../utils/history'
import { getAuthCodeError } from '../../auth/authErrorCodes'
import { isUIWebView } from '../../utils/helpers'

const LoginModal = () => {
  const dispatch = useDispatch()
  const authModal = useSelector((state) => state.router.location.query)
  const [errorMessage, setErrorMessage] = useState()
  const initialValues = {
    email: authModal.username || '',
    password: ''
  }

  const newSignUp = authModal.username ? true : false

  return (
    <>
      <Modal
        show
        className='modal-full-width-sm auth-modal'
        onHide={() => dispatch(closeModal())}
      >
        <Modal.Header className='bg-light'>
          <button className='close' onClick={() => dispatch(closeModal())}>
            <i className='fas fa-long-arrow-alt-left d-block d-md-none'></i>
            <span className='d-none d-md-block'>&times;</span>
          </button>
          <img
            src={logoSE}
            className='img-fluid mx-auto py-4 d-none d-lg-block'
            alt='Shared Earth logo'
          />
          <h2 className='text-center w-100 d-block d-lg-none mb-0'>Login</h2>
        </Modal.Header>
        {/* TODO github issue #67 - need to tie this in to welcome new users and let them know to login */}
        {newSignUp && (
          <p className='text-center alert alert-success p-3'>
            Thanks for signing up! Please login to get started
          </p>
        )}
        <Modal.Body>
          <>
            <div className='row mt-4 justify-content-center '>
              <div className='col-12 col-lg-7'>
                <div className='row justify-content-center justify-content-lg-start auth-buttons'>
                  <div className='col-12 mb-2'>
                    <button
                      type='button'
                      onClick={() =>
                        userAuthSocialSignIn({ provider: 'Facebook' })
                      }
                      className='btn btn-block btn-blue-dark btn-icon justify-content-center justify-content-lg-start'
                    >
                      <div>
                        <span className='icon-wrapper'>
                          <i className='fab fa-facebook btn-icon' />
                        </span>
                        <span className='d-none d-lg-inline-block'>
                          Login with&nbsp;
                        </span>
                        Facebook
                      </div>
                    </button>
                  </div>
                  {!isUIWebView && (
                    <div className='col-lg-12 mb-2'>
                      <button
                        onClick={() =>
                          userAuthSocialSignIn({ provider: 'Google' })
                        }
                        className='btn btn-block btn-red-dark btn-icon justify-content-center justify-content-lg-start'
                      >
                        <div>
                          <span className='icon-wrapper'>
                            <i className='fab fa-google' />
                          </span>
                          <span className='d-none d-lg-inline-block'>
                            Login with&nbsp;
                          </span>
                          Google
                        </div>
                      </button>
                    </div>
                  )}
                  <div className='col-12 mb-2'>
                    <button
                      onClick={() =>
                        userAuthSocialSignIn({ provider: 'SignInWithApple' })
                      }
                      className='btn btn-block btn-dark btn-icon justify-content-center justify-content-lg-start'
                    >
                      <div>
                        <span className='icon-wrapper'>
                          <i className='fab fa-apple' />
                        </span>
                        <span className='d-none d-lg-inline-block'>
                          Login with&nbsp;
                        </span>
                        Apple
                      </div>
                    </button>
                  </div>
                </div>
                <hr className='mt-5' />
                <p className='text-center mt-4'>Or login with email</p>
                {errorMessage && (
                  <div className='alert alert-warning mb-2' role='alert'>
                    <p>{errorMessage}</p>
                  </div>
                )}
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  onSubmit={async (values, { resetForm }) => {
                    try {
                      await dispatch(
                        userAuthSignIn({
                          username: values.email,
                          password: values.password
                        })
                      )
                      resetForm()
                      dispatch(closeModal())
                      history.push('/map')
                      history.go()
                    } catch (error) {
                      if (error.code === 'PasswordResetRequiredException') {
                        history.push(
                          `?showResetPasswordModal=true&username=${values.email}&returning=true`
                        )
                      }
                      if (error.code === 'UserNotConfirmedException') {
                        history.push(
                          `?showAuthCodeModal=true&username=${values.email}`
                        )
                      }

                      setErrorMessage(getAuthCodeError(error.name))
                    }
                  }}
                >
                  {() => (
                    <Form>
                      <Field
                        name='email'
                        type='email'
                        className='input-icon input-email'
                        component={TextInput}
                        placeholder='Enter Email'
                      />
                      <Field
                        name='password'
                        type='password'
                        className='input-icon input-password'
                        component={TextInput}
                        placeholder='Enter Password'
                      />
                      <button
                        type='submit'
                        className='btn btn-blue-light btn-block'
                      >
                        Login
                      </button>
                    </Form>
                  )}
                </Formik>
                {/* <p className='text-center text-gray-500 mt-3'>
                  <small>
                    We will never share your address without your permission.
                    &nbsp; View our <a href='/privacy-policy'>Privacy Policy</a>
                    .
                  </small>
                </p> */}
                {/* TODO github issue #66 - need to tie this text in for users to reset forgotten password */}
                <p className='text-center text-gray-500 mt-5'>
                  <small>
                    Forgot Password?{' '}
                    <a
                      href='!#'
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('?showResetPasswordRequestModal=true')
                      }}
                    >
                      Click here to reset
                    </a>
                  </small>
                </p>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

// LoginModal.propTypes = {

// }

export default LoginModal
