import React from 'react'
import PropTypes from 'prop-types'
import { Field } from "formik"
import {
  TextArea,
  SelectInput,
  FileUpload,
} from "../fields"

import {
  gardenSizeOptions,
  gardenStatusOptions,
} from "../../common/data/selects"

const HaveFields = ({ setFieldValue, listing }) => {
  return (
    <>
      <Field
        name="about"
        type="text"
        component={TextArea}
        label="About this land"
      />
      
      <Field
        name="garden_size"
        type="text"
        component={SelectInput}
        options={gardenSizeOptions}
        onChange={({value}) => setFieldValue('garden_size', value)}
        label="Land Size"
      />

      <Field
        name="garden_status"
        type="text"
        component={SelectInput}
        options={gardenStatusOptions}
        onChange={({ value }) => setFieldValue('garden_status', value)}
        label="Land Status"
      />


      <Field
        name="images"
        type="file"
        component={FileUpload}
        multiple={true}
        label="Land Images"
        className="row row-cols-1 row-cols-md-2 row-cols-lg-3"
      />
    </>
  )
}

HaveFields.propTypes = {
  setFieldValue: PropTypes.instanceOf(Function)
}

export default HaveFields
