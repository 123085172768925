import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../modalActions";
import { Field, Form, Formik } from "formik";
import { history } from "../../utils/history";
import { authUserValidationCode } from "../../auth/authActions";
import ErrorBanner from "../../ui/ErrorBanner";
import logoSE from "../../../common/assets/shared-earth-vertical.svg";
import { getAuthCodeError } from "../../auth/authErrorCodes";

const AuthCodeModal = ({ heading }) => {
  const dispatch = useDispatch();
  const authModal = useSelector((state) => state.router.location.query);
  const initialValues = {
    username: authModal.username || "", // note this is actually email, aws requires username
    code: "",
  };

  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (values, resetForm) => {
    const { username, code } = values;
    try {
      await dispatch(authUserValidationCode({ username, code }));
      history.push("/map");
      history.go()
      dispatch(closeModal());
    } catch (error) {
      console.log(error)
      setErrorMessage(getAuthCodeError(error.name));
    }
  };

  return (
    <>
      <Modal 
        show 
        className="modal-full-width-sm auth-modal"
        onHide={() => dispatch(closeModal())}>
        <Modal.Header className="bg-light">
          <button className="close" onClick={() => dispatch(closeModal())}>
            <i className="fas fa-long-arrow-alt-left d-block d-md-none"></i>
            <span className="d-none d-md-block">&times;</span>
          </button>
          <img
            src={logoSE}
            className="img-fluid d-block mx-auto py-4"
            alt="Shared Earth logo"
          />
        </Modal.Header>
        <Modal.Body>
          <>
            <h4 className="mt-4 mb-3 text-center">
              To continue registration, please verify your account with the confirmation code we just sent to your email.
            </h4>
            <ErrorBanner errorMessage={errorMessage} />
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
            >
              {({ values, setFieldValue, dirty }) => (
                <Form id="profile-form">
                  <div className="form-group">
                    Username:
                    <Field
                      className="form-control"
                      type="username"
                      name="username"
                      value={values.username}
                      disabled={true} // don't want to modify this, just display it
                    />
                  </div>
                  <div className="form-group">
                    Code:
                    <Field
                      className="form-control"
                      type="code"
                      name="code"
                      value={values.code}
                    />
                  </div>
                  <div className="form-group pt-3 mb-1">
                    <button
                      type="submit"
                      form="profile-form"
                      className={`btn btn-save  btn-block ${
                        dirty ? "btn-blue-light" : "btn-gray-500 btn-disabled"
                      }`}
                      disabled={dirty ? false : true}
                    >
                      Verify
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

// AuthCodeModal.propTypes = {

// }

export default AuthCodeModal;
