import React from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import ReactGA4 from 'react-ga4'
import { updateProfile } from '../currentUser/profileActions'
import { closeModal } from './modalActions'
import DonateFormStripe from '../ui/DonateFormStripe'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { history } from '../utils/history'

const DonateModal = () => {
  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.currentUser.profile)
  const isLoggedIn = useSelector((state) =>
    state.currentUser.auth && state.currentUser.profile ? true : false
  )
  const closeModalAction = async (track = true) => {
    if (userProfile && userProfile.show_donate_prompt) {
      await dispatch(updateProfile({ show_donate_prompt: false }))
      return
    } else {
      if (track) {
        ReactGA4.event({
          category: 'Manual Donation Modal Event',
          action: 'Donation Modal Closed'
        })
      }
      history.replace(history.location.pathname)
      dispatch(closeModal())
    }
  }

  const showCloseButton =
    !isLoggedIn || (userProfile && !userProfile.show_donate_prompt)

  const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY
  const stripePromise = loadStripe(publishKey)

  return (
    <>
      <Modal
        show
        id='modal-donate'
        className='modal-full-width-sm'
        onHide={() => closeModalAction()}
      >
        <Modal.Header className='text-center'>
          {showCloseButton && (
            <button
              className='close close-custom'
              onClick={() => closeModalAction()}
            >
              <span className='d-block d-md-none'>
                <i className='fas fa-chevron-left mr-2'></i>
                Back
              </span>
              <span className='d-none d-md-block'>&times;</span>
            </button>
          )}
          <Modal.Title className='mt-4 mt-lg-0'>
            <h2 className='text-primary'>Make a Donation</h2>
            <h4>
              Donate to Shared Earth to help continue connecting Gardens with
              Gardeners
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='py-5 px-md-5'>
          {/* <DonateForm /> */}
          <Elements stripe={stripePromise}>
            <DonateFormStripe />
          </Elements>
        </Modal.Body>
        <Modal.Footer>
          <small>
            Shared Earth is a project of Sustainable America, a 501(c)(3)
            non-profit organization with the mission to make America’s food and
            fuel systems more efficient and resilient.
          </small>
          {userProfile && userProfile.show_donate_prompt && (
            <button
              className='btn btn-blue-light btn-block mt-3'
              onClick={() => closeModalAction(false)}
            >
              Skip
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

// DonateModal.propTypes = {

// }

export default DonateModal
