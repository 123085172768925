import { createBrowserHistory } from 'history'
import { persistReducer } from 'redux-persist'
import storage from 'localforage';  // https://blog.logrocket.com/localforage-managing-offline-browser-storage/
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { getPersistConfig } from 'redux-deep-persist';
import rootReducer from './reducers'
import { locationTransforms } from './localStorage'

export const history = createBrowserHistory()

export default function createReduxStore(initialState = {}) {
  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  if (window && window.location && process.env.REACT_APP_SHOW_DEBUG) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension({ trace: false }))
    }
  }

  const rr = rootReducer(history)

  const persistConfig = getPersistConfig({ // configuration object for redux-persist
    key: 'reduxStore',
    storage, // define which storage to use
    transforms: [ locationTransforms ],
    whitelist:['locations.allLocations'],
    rootReducer: rr
  })

  

  const persistedReducer = persistReducer(persistConfig, rr) // create a persisted reducer

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    routerMiddleware(history),
    thunk
    // This is where you add other middleware like redux-observable
  ]

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  )

  return store
}