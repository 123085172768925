import React from "react";
import Select from "react-select";
import LocationDetails from "../location/LocationDetails";

const ProfileMapSelector = ({
  locationOptions,
  selectedLocation,
  activeLocation,
  profile,
  showDetails,
  onChange,
}) => {
  return (
    <>
      {/* SELECT LOCATION */}
      {locationOptions.length > 1 && (
        <>
          <div className="row my-4">
            <div className="col-12">
              <div className="d-md-flex align-items-center">
                <span className="text-nowrap mr-3">
                  Explore {profile.first_name}'s listings:
                </span>
                <div className="react-select-container mt-2 mt-md-0 w-100">
                  <Select
                    isSearchable={false}
                    value={selectedLocation}
                    options={locationOptions}
                    classNamePrefix="react-select"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* LOCATION DETAILS */}
      <LocationDetails
        firstName={profile.first_name}
        location={activeLocation}
        showDetails={showDetails}
      />
    </>
  );
};

export default ProfileMapSelector;
