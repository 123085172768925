/**
 * Takes an object and removes any fields that are null or undefined.
 *
 * @param {Object} obj
 * @returns A object without any undefined or null poperties
 */
export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

const toRad = (Value) => {
  /** Converts numeric degrees to radians */
  return (Value * Math.PI) / 180
}

/**
 * Calculates the distance from the user
 * Takes an array of [lat, lng]
 *
 * @param {array} usersLocation
 * @param {array} nodeLocation
 * @param {*} unit
 */
export const calculateDistanceFromUser = (usersLocation, nodeLocation) => {
  const [lon1, lat1] = usersLocation
  const [lon2, lat2] = nodeLocation

  //Radius of the earth in:  1.609344 miles,  6371 km  | var R = (6371 / 1.609344);
  var R = 3958.7558657440545 // Radius of earth in Miles
  var dLat = toRad(lat2 - lat1)
  var dLon = toRad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

// export const isUIWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
//   navigator.userAgent
// )

export const isUIWebView =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
