import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { AddressInputMap, ListingTypeInput, SwitchInput } from '../fields'
import HaveFields from './HaveFields'
import NeedFields from './NeedFields'
import { closeDrawer } from '../drawers/drawerActions'
import {
  addLocationToProfile,
  removeLocationImage,
  updateLocationOnProfile,
  updateProfile
} from '../currentUser/profileActions'
import { uploadImagesToS3 } from '../utils/s3bucketHelper'

const ListingForm = ({ listing }) => {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.currentUser.profile)
  const defaultValues = {
    about: '',
    default: profile.default_location_id === listing.id ? true : false,
    images: []
  }
  const initialValues = { ...defaultValues, ...listing }
  const validationSchema = Yup.object().shape({
    formatted_address: Yup.string().required('Required'),
    type: Yup.string().required('Required')
  })

  const handleSubmit = async (values) => {
    try {
      const { images } = values
      if (!values.is_enabled) {
        values.is_enabled = false
      }
      
      const newListing = { user_id: profile.id, ...values, ...values.location }

      delete newListing.location
      delete newListing.images
      delete newListing.default

      // json encode multi selects
      // if (location.interested_growing && location.interested_growing.length) {
      //   location.interested_growing = JSON.stringify(location.interested_growing)
      // }
      const gqlLocation = listing.id
        ? await dispatch(updateLocationOnProfile(listing.id, newListing))
        : await dispatch(addLocationToProfile(newListing))
      const location_id = gqlLocation.id

      // if the original listing had images and the update values do not, remove them from the
      // console.log(initialValues.images)
      const differentImages = initialValues.images.filter(
        (x) => !images.includes(x)
      )
      if (differentImages && differentImages.length) {
        for (let index = 0; index < differentImages.length; index++) {
          await dispatch(removeLocationImage(differentImages[index].id))
        }
      }

      // Upload images to s3
      if (images && images.length) {
        await uploadImagesToS3({
          dispatch,
          location_id,
          profile,
          images
        })
      }

      // If the user has only one location, and the profile doesn't already have a default id, force set the default location
      // OR
      // if the values are set to default = true, set the location as a default
      if (
        (profile.locations.length === 1 && !profile.default_location_id) ||
        values.default
      ) {
        await dispatch(updateProfile({ default_location_id: location_id }))
      }

      // if default is unchecked, and the profile default id is the same as the current location id, uncheck it
      else if (!values.default && location_id === profile.default_location_id) {
        await dispatch(updateProfile({ default_location_id: null }))
      }

      dispatch(closeDrawer())
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ errors, values, setFieldValue, dirty, isValid }) => (
        <Form id='listingForm'>
          <div className='row'>
            <div className='col-12'>
              <h3>Listing Type</h3>
              <Field name='type' type='text' component={ListingTypeInput} />
            </div>
          </div>
          <hr />

          {values['type'] && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <h3>Address</h3>
                  <Field
                    name='formatted_address'
                    type='text'
                    component={AddressInputMap}
                    className='position-relative'
                    defaultLocation={listing}
                    onChange={(value) =>
                      setFieldValue('formatted_address', value)
                    }
                  />
                </div>
              </div>
              <hr />
              {values['type'] === 'have' ? (
                <HaveFields setFieldValue={setFieldValue} listing={listing} />
              ) : (
                <NeedFields setFieldValue={setFieldValue} />
              )}
            </>
          )}
          {profile && profile.locations.length > 1 && (
            <div className='row'>
              <div className='col-12'>
                <Field
                  className='form-inline'
                  name={`default`}
                  type='checkbox'
                  component={SwitchInput}
                  onChange={(e, value) => {
                    setFieldValue('default', value.checked)
                  }}
                  label='Set as Default Location'
                />
              </div>
            </div>
          )}
          {listing.id ? (
            <div className='row'>
              <div className='col-12'>
                <button
                  type='button'
                  form='profile-form'
                  className={`btn btn-outline-blue-light`}
                  onClick={async () => {
                    await dispatch(
                      updateLocationOnProfile(listing.id, {
                        is_enabled: !listing.is_enabled
                      })
                    )
                    dispatch(closeDrawer())
                  }}
                >
                  {listing.is_enabled ? 'Hide Listing' : 'Show Listing'}
                </button>
              </div>
            </div>
          ) : (
            <div className='row'>
              <div className='col-12'>
                <Field
                  name={`is_enabled`}
                  type='checkbox'
                  component={SwitchInput}
                  onChange={(e, value) =>
                    setFieldValue('is_enabled', value.checked)
                  }
                  label='Share my listings'
                />
              </div>
            </div>
          )}
          <div className='row mt-5'>
            <div className='col-12'>
              {dirty && (
                <button
                  type='submit'
                  form='listingForm'
                  className={`btn btn-blue-light ${isValid ? '' : 'disabled'}`}
                  disabled={!isValid}
                >
                  Save
                </button>
              )}
            </div>
          </div>
          {/* <FormikDebug /> */}
        </Form>
      )}
    </Formik>
  )
}

ListingForm.propTypes = {
  listing: PropTypes.instanceOf(Object)
}

ListingForm.defaultProps = {
  listing: {}
}

export default ListingForm
