import React from 'react'
import PropTypes from 'prop-types'
import { getCloudFrontImage } from "../utils/s3bucketHelper"

const FilePreview = ({ file, children, className  }) => {

  const previewOptions = {
    resize: {
      width: 260,
      height: 260,
      fit: "cover",
    },
  }
  const src = file.id
    ? getCloudFrontImage(file.path, previewOptions)
    : URL.createObjectURL(file)

  return (
    <div className={className}>
      <div
        className="file-preview bg-image aspect-1x1"
        style={{ backgroundImage: `url(${src})`}}>
        {children}
      </div>
    </div>

    // <div className={`card mt-3`} >
    //   <img src={src} className="card-img-top" alt="" />
    //   <div className="card-footer text-center" onClick={() => handleDelete(file)}>
    //     <p className="py-2 text-small"><i className="far fa-times-circle" /> Remove</p>
    //   </div>
    // </div>
  )
}

FilePreview.propTypes = {
  file: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

export default FilePreview
