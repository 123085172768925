import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../modalActions";
import { Field, Form, Formik } from "formik";
import { TextInput } from "../../fields";
import {
  authForgotPasswordGetCode,
  authForgotPasswordReset,
} from "../../auth/authActions";
import { history } from "../../utils/history";
import ErrorBanner from "../../ui/ErrorBanner";
import logoSE from "../../../common/assets/shared-earth-vertical.svg";
import { getAuthCodeError } from "../../auth/authErrorCodes";

const ReturningUserModal = ({ modalMessage }) => {
  const dispatch = useDispatch();
  const authModal = useSelector((state) => state.router.location.query);
  const [errorMessage, setErrorMessage] = useState();

  // If there is no user name we don't want to use this modal, close it
  if (!authModal.username) {
    dispatch(closeModal());
  }

  const initialValues = {
    email: authModal.username,
    code: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values, resetForm) => {
    const { email, password, confirmPassword, code } = values;

    if (password !== confirmPassword) {
      setErrorMessage(getAuthCodeError('PasswordMissMatch'));
      return;
    }

    try {
      await dispatch(
        authForgotPasswordReset({ username: email, password, code })
      );
      history.push("/map");
      history.go()
    } catch (error) {
      setErrorMessage(getAuthCodeError(error.name));
    }
  };

  return (
    <>
      <Modal
        show
        className="modal-full-width-sm auth-modal"
        onHide={() => dispatch(closeModal())} size="lg">
        <Modal.Header className="bg-light">
          <button className="close" onClick={() => dispatch(closeModal())}>
            <i className="fas fa-long-arrow-alt-left d-block d-md-none"></i>
            <span className="d-none d-md-block">&times;</span>
          </button>
          <img
            src={logoSE}
            className="img-fluid mx-auto py-4 d-none d-lg-block"
            alt="Shared Earth logo"
          />
          <h2 className="text-center w-100 d-block d-lg-none mb-0">Reset Password</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3 px-lg-5">
            <div className="row justify-content-center">
              <div className="col-12">
                <h3 className="mb-3 text-center">Welcome Back!</h3>
                <p className="text-center">
                  {modalMessage}
                </p>
                <p className="text-center">
                  If you didn't get a confirmation code, click{" "}
                  <a
                    href="#1"
                    onClick={async (e) => {
                      e.preventDefault();
                      await dispatch(
                        authForgotPasswordGetCode(authModal.username)
                      );
                    }}
                  >
                    here
                  </a>{" "}
                  to try again
                </p>
                <ErrorBanner errorMessage={errorMessage} />
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { resetForm }) =>
                    handleSubmit(values, resetForm)
                  }
                >
                  {({ values, setFieldValue, dirty }) => (
                    <Form id="profile-form" className="mt-5">
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="code">
                              <h4>Account Email</h4>
                            </label>
                            <Field
                              className="form-control"
                              type="email"
                              name="email"
                              label="Account Email"
                              disabled={true} // don't want to modify this, just display it
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <label htmlFor="code">
                              <h4>Code</h4>
                            </label>
                            <Field
                              className="form-control"
                              type="code"
                              name="code"
                              value={values.code}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="form-group">
                            <Field
                              name="password"
                              type="password"
                              component={TextInput}
                              onChange={(value) =>
                                setFieldValue("password.newPassword", value)
                              }
                              label="Password"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="form-group">  
                            <Field
                              name="confirmPassword"
                              type="password"
                              component={TextInput}
                              onChange={(value) =>
                                setFieldValue("confirmPassword", value)
                              }
                              label="Confirm Password"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group pt-3 mb-1">
                            <button
                              type="submit"
                              form="profile-form"
                              className={`btn btn-save btn-block  ${
                                dirty ? "btn-blue-light" : "btn-gray-500 btn-disabled"
                              }`}
                              disabled={dirty ? false : true}
                            >
                              Log In
                            </button>
                          </div>
                        </div>
                      </div>
                      
                      
                      
                      
                      
                      {/* <FormikDebug /> */}
                    </Form>
                  )}
                </Formik>
                {/* <p className="text-center text-gray-500">
                  <small>
                    By signing up, you agree to Shared Earth’s{" "}
                    <a href="/terms-of-use">Terms of Use</a> and{" "}
                    <a href="/privacy-policy">Privacy Policy</a>.
                  </small>
                </p> */}
                <p className="text-center text-gray-500 mt-5">
                  <small>
                    Already have an account?{" "}
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("?showLoginModal=true");
                      }}
                    >
                      Login!
                    </a>
                  </small>
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ReturningUserModal.propTypes = {

// }

export default ReturningUserModal;
