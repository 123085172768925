import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap'
import { closeModal } from './modalActions'
import { Formik, Form, Field } from 'formik'
import { AddressInputMap, ListingTypeInput, SwitchInput } from '../fields'
import { addLocationToProfile } from '../currentUser/profileActions'
import { setActiveLocation } from '../locations/locationsAction'

const NewLocationModal = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.currentUser.profile)

  const validationSchema = Yup.object().shape({
    formatted_address: Yup.string().required('Required'),
    type: Yup.string().required('Required')
  })

  const handleSubmit = async (values, resetForm) => {
    try {
      // Make a deep copy of values, we need location within the main object (parent level)
      const location = { user_id: user.id, ...values, ...values.location }
      delete location.location // Because spread operator on location is used above, remove it from the initial values
      const gqlLocation = await dispatch(addLocationToProfile(location))
      dispatch(setActiveLocation(gqlLocation))

      dispatch(closeModal())
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <>
      <Modal
        show
        className='modal-full-width-sm'
        onHide={() => dispatch(closeModal())}
        size='lg'
      >
        <Modal.Header className='border-0'>
          <button
            className='close close-custom'
            onClick={() => dispatch(closeModal())}
          >
            <span className='d-block d-md-none'>
              <i className='fas fa-chevron-left mr-2'></i>
              Back
            </span>
            <span className='d-none d-md-block'>&times;</span>
          </button>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        >
          {({ errors, values, setFieldValue, dirty }) => {
            return (
              <Form id='new-address-quick-form'>
                <div className='p-3'>
                  <div className='row'>
                    <div className='col-12'>
                      <h3 className='text-center py-3'>Add Address</h3>
                      <Field
                        name='formatted_address'
                        type='text'
                        component={AddressInputMap}
                        className='position-relative'
                        placeholder='Enter Address'
                        onChange={(value) => {
                          setFieldValue('formatted_address', value)
                        }}
                      />
                    </div>
                    <div className='col-12'>
                      <Field
                        name='type'
                        type='text'
                        component={ListingTypeInput}
                      />
                    </div>
                    <div className='col-12 mb-3 text-center'>
                      <Field
                        name={`is_enabled`}
                        type='checkbox'
                        component={SwitchInput}
                        onChange={(e, value) =>
                          setFieldValue(
                            'is_enabled',
                            value.checked
                          )
                        }
                        label='Share my listings'
                      />
                    </div>
                    <div className='col-12 mb-3 text-center'>
                      <button
                        type='submit'
                        form='new-address-quick-form'
                        className={`btn btn-save px-lg-5 ${
                          dirty ? 'btn-blue-light' : 'btn-gray-500 btn-disabled'
                        }`}
                        disabled={dirty ? false : true}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                {/* <FormikDebug /> */}
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default NewLocationModal
