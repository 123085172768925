import React from "react";
import { useSelector } from "react-redux";
import LocationIcon from '../ui/LocationIcon'

const LocationCard = ({ data, children }) => {
  const userProfile = useSelector((state) => state.currentUser.profile);
  const { id, formatted_address, type, is_enabled } = data;
  const isDefault = userProfile.default_location_id === id;
  return (
    <>
      <div className="col mb-4">
        <div
          className={`card border-0 h-100 text-center location-card ${
            isDefault ? "bg-green-light" : "bg-light"
          }`}
        >
          <div className="card-body">
            {isDefault && (
              <div className="location-default-text">
                <p className="text-uppercase">
                  <small>(Default Location)</small>{" "}
                </p>
              </div>
            )}
            {!is_enabled && (
              <div className="location-default-text">
                <p className="text-uppercase">
                  <small>(Hidden Listing)</small>{" "}
                </p>
              </div>
            )}

            <LocationIcon type={type} className="icon-xl" />

            <h6 className="card-title fw-bold mb-1">
              {type === "need" ? "Gardener Listing" : "Land Listing"}
            </h6>
            <small>
              <address>{formatted_address}</address>
            </small>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default LocationCard;
