import { gql } from 'graphql-request'

// CREATE
export const GQL_BLOCK_USER = gql`
  mutation insert_blocked_users_one($user_id: Int!, $blocked_user_id: Int!) {
    insert_blocked_users_one(
      object: { user_id: $user_id, blocked_user_id: $blocked_user_id }
    ) {
      user_id
      blocked_user_id
    }
  }
`

// DELETE
export const GQL_UNBLOCK_USER = gql`
  mutation UnblockUser($user_id: Int!, $blocked_user_id: Int!) {
    delete_blocked_users(where: {user_id: {_eq: $user_id}, blocked_user_id: {_eq: $blocked_user_id}}) {
      affected_rows
    }
  }
`