import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import ReduxToastr from 'react-redux-toastr'
import { history } from '../common/utils/history.js'
import Routes from '../routes/index.js'
import ModalWrapper from '../common/modals/ModalWrapper'
import { validateSession } from '../common/auth/authActions'
import LoadingComponent from '../common/ui/LoadingComponent'
import { Hub } from '@aws-amplify/core';
import NotificationWrapper from '../common/notifications/NotificationWrapper.jsx';
import usePageTracking from '../common/hooks/usePageTracking.js';


const App = () => {
  const dispatch = useDispatch()
  usePageTracking()


  // if (profile && !isProfileComplete(profile[ 0 ])) {
  //   dispatch(openNotification('ProfileNotification'))
  // }
  // else if ((profile && isProfileComplete(profile[ 0 ])) || !isLoggedIn) {
  //   dispatch(closeNotification())
  // }

  useEffect(() => {
    const authInit = async () => {
      try {
        Hub.listen('auth', async ({ payload: { event, data } }) => {
          switch (event) {
            case 'cognitoHostedUI':
              // this is called when google oAuth is called
              history.push('/map')
              history.go()
              break;
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
              break;
            default:
              // console.log('default', event, data)
              break;
          }
        }); // Add this component as a listener of auth events.
        await dispatch(validateSession());
      } catch (error) {
      }

    }
    authInit()

  }, [ dispatch ])



  return (
    <>
      <ConnectedRouter history={ history }>
        <LoadingComponent />
        <ModalWrapper />
        <NotificationWrapper />
        <ReduxToastr
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
        <Routes />
      </ConnectedRouter>
    </>
  )
}

// App.propTypes = {
// }

export default App
