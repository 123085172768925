import { Auth } from 'aws-amplify'
import { toastr } from 'react-redux-toastr'
import { graphqlClient } from '../../graphql/graphqlClient'
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart
} from '../async/asyncActions'
import {
  ADD_LOCATION_IMAGES,
  BLOCK_USER,
  CREATE_NEW_CONVERSATION,
  CREATE_PROFILE_LOCATION,
  LOAD_PROFILE,
  REMOVE_LOCATION_IMAGES,
  REMOVE_PROFILE,
  UNBLOCK_USER,
  UPDATE_PROFILE,
  UPDATE_PROFILE_LOCATION
} from './profileConstants'
// graphql
import {
  GQL_ADD_COGNITO_ID_TO_PROFILE,
  GQL_CREATE_PROFILE,
  GQL_UPDATE_PROFILE
} from '../../graphql/hasura/profile/mutations'
import {
  GQL_CREATE_LOCATION,
  GQL_UPDATE_LOCATION
} from '../../graphql/hasura/locations/mutations'
import {
  GQL_GET_PROFILE_BY_EMAIL,
  GQL_GET_PROFILE_BY_ID,
  GQL_GET_PROFILE_CONVERSATIONS
} from '../../graphql/hasura/profile/queries'
import {
  GQL_CREATE_CONVERSATION,
  GQL_CREATE_CONVERSATION_LINK
} from '../../graphql/hasura/messages/mutations'
import {
  GQL_ADD_IMAGES,
  GQL_REMOVE_IMAGE
} from '../../graphql/hasura/images/mutations'
import { setActiveConversation } from '../conversations/conversationActions'
import { closeModal, openModal } from '../modals/modalActions'
import { userAuthSignOut } from '../auth/authActions'
import { isUIWebView } from '../utils/helpers'
import { GQL_BLOCK_USER, GQL_UNBLOCK_USER } from '../../graphql/hasura/blocked_users/mutations'
import { GQL_BLOCKED_USERS } from '../../graphql/hasura/blocked_users/queries'

export const loadProfile = (currentAuthenticatedUser) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    if (!currentAuthenticatedUser) {
      throw new Error('No current authenticated user found')
    }

    const { email, sub } = currentAuthenticatedUser.attributes
    const gqlClient = await graphqlClient()
    const awsUserEmail =
      currentAuthenticatedUser && currentAuthenticatedUser.attributes.email

    // attempt to load the profile from hasura
    const profileQuery = await gqlClient.request(GQL_GET_PROFILE_BY_EMAIL, {
      email: awsUserEmail
    })
    let loadedProfile = profileQuery.profile[0]

    // Bounce user if their profile has been archived for over 30 days
    if (loadedProfile?.archived_date) {
      const archivedDate = new Date(loadedProfile.archived_date)
      const currentDate = new Date()

      // Calculate the difference in milliseconds
      const diffInMs = currentDate - archivedDate

      // Convert milliseconds to days
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

      if (diffInDays > 30) {
        dispatch(userAuthSignOut())
      }
    }

    // if there is no profile, create a new profile and update the cognito user attributes for next time
    if (!loadedProfile) {
      const {
        insert_profile: { returning }
      } = await gqlClient.request(GQL_CREATE_PROFILE, {
        email,
        cognitoSub: sub
      })
      const newProfile = returning[0]
      const postgresProfileId = newProfile.id.toString()
      await Auth.updateUserAttributes(currentAuthenticatedUser, {
        'custom:postgres_uid': postgresProfileId
      })

      // Analytics.record({
      //   name: 'create_user_profile',
      //   attributes: {
      //     user: JSON.stringify({ postgres_id: postgresProfileId, email, cognitoSub: sub })
      //   }
      // })

      // re-assign loaded profile with the new profile
      loadedProfile = newProfile
    }

    // if there is a profile, check to see if cognito_sub exists on it, if not update the profile
    if (!loadedProfile.cognito_sub) {
      const updatedProfile = await gqlClient.request(
        GQL_ADD_COGNITO_ID_TO_PROFILE,
        { email, cognitoSub: sub }
      )

      // Analytics.record({
      //   name: 'update_user_profile_with_cognito',
      //   attributes: {
      //     user: JSON.stringify({ postgres_id: loadedProfile.id, cognitoSub: sub })
      //   }
      // })

      // re-assign loaded profile with the updated profile
      loadedProfile = updatedProfile.update_profile.returning[0]
    }

    // check if the loaded profile id matches the cognito custom:postgres_uid. If it doesn't we'll need to update it.
    if (
      loadedProfile.id &&
      loadedProfile.id !==
        currentAuthenticatedUser.attributes['custom:postgres_uid']
    ) {
      await Auth.updateUserAttributes(currentAuthenticatedUser, {
        'custom:postgres_uid': loadedProfile.id.toString()
      })
    }

    // check locations, if the user only has one, set that as their default profile
    if (
      loadedProfile.locations &&
      !loadedProfile.default_location_id &&
      loadedProfile.locations.length === 1
    ) {
      loadedProfile = await dispatch(
        updateProfile({ default_location_id: loadedProfile.locations[0].id })
      )
    }

    modalHelper(loadedProfile, dispatch)

    // Load the blocked users
    const {blocked_users} = await gqlClient.request(GQL_BLOCKED_USERS, {
      user_id: loadedProfile.id
    })

    loadedProfile.blockedUsers = blocked_users

    

    dispatch({
      type: LOAD_PROFILE,
      payload: {
        type: LOAD_PROFILE,
        data: loadedProfile
      }
    })

    dispatch(asyncActionFinish())

    return loadedProfile
  }
}

export const getProfileByEmail = (email) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const profileQuery = await gqlClient.request(GQL_GET_PROFILE_BY_EMAIL, {
        email
      })
      dispatch(asyncActionFinish())
      return profileQuery.profile[0]
    } catch (error) {
      // surpress error
      dispatch(asyncActionError())
    }
  }
}

export const getProfileById = (profileId) => {
  return async (dispatch, getState) => {
    // dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const profileQuery = await gqlClient.request(GQL_GET_PROFILE_BY_ID, {
        id: profileId
      })

      // dispatch(asyncActionFinish())
      return profileQuery.profile[0]
    } catch (error) {
      // surpress error
      // dispatch(asyncActionError());
    }
  }
}

export const updateProfile = (profileObject) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser() // TODO: Pass this in

      if (!currentAuthenticatedUser) {
        dispatch(asyncActionError())
        throw new Error('No current authenticated user found')
      }

      // Update the user email address
      if (profileObject.username) {
        // Verify that the email address isnt already in use
        const profileByEmail = await dispatch(
          getProfileByEmail(profileObject.username)
        )
        if (profileByEmail) {
          toastr.error('Oops!', 'Email Already in use')
          dispatch(asyncActionError())
          throw new Error('Email Already in use')
        }

        await Auth.updateUserAttributes(currentAuthenticatedUser, {
          email: profileObject.username
        })
      }

      const gqlClient = await graphqlClient()
      // TODO: Pass the userId in
      const postgresUid =
        currentAuthenticatedUser &&
        currentAuthenticatedUser.attributes &&
        currentAuthenticatedUser.attributes['custom:postgres_uid']
          ? currentAuthenticatedUser.attributes['custom:postgres_uid']
          : -1
      const profile = {
        id: parseInt(postgresUid, 10),
        changes: { ...removePrivateProfileFields(profileObject) }
      }

      const updatedProfile = await gqlClient.request(GQL_UPDATE_PROFILE, {
        ...profile
      })
      const p = updatedProfile.update_profile.returning[0]

      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          type: UPDATE_PROFILE,
          data: p
        }
      })

      modalHelper(p, dispatch)

      dispatch(asyncActionFinish())
      toastr.success('Success', 'Profile has been updated')
      return p
    } catch (error) {
      dispatch(asyncActionError())
      toastr.error('Oops!', 'Could not update your profile')
      throw new Error('Could not update profile')
    }
  }
}

export const removeProfile = () => {
  return {
    type: REMOVE_PROFILE,
    payload: {
      type: REMOVE_PROFILE,
      data: {}
    }
  }
}

export const addLocationToProfile = (locationObject) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const {
        insert_locations_location: { returning }
      } = await gqlClient.request(GQL_CREATE_LOCATION, {
        objects: { ...locationObject }
      })
      const newLocation = returning[0]
      dispatch({
        type: CREATE_PROFILE_LOCATION,
        payload: { type: CREATE_PROFILE_LOCATION, data: newLocation }
      })

      toastr.success('Success', 'Location has been created')
      dispatch(asyncActionFinish())

      return newLocation
    } catch (error) {
      dispatch(asyncActionError())
      toastr.error('Error', 'Location could not be created')
      console.log(error)
    }
  }
}

export const updateLocationOnProfile = (locationId, locationObject) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const location = {
        id: parseInt(locationId, 10),
        changes: { ...removePrivateLocationFields(locationObject) }
      }

      const {
        update_locations_location: { returning }
      } = await gqlClient.request(GQL_UPDATE_LOCATION, { ...location })
      dispatch({
        type: UPDATE_PROFILE_LOCATION,
        payload: {
          type: UPDATE_PROFILE_LOCATION,
          data: returning[0]
        }
      })

      dispatch(asyncActionFinish())
      toastr.success('Success', 'Location has been updated')

      return returning[0]
    } catch (error) {
      dispatch(asyncActionError())
      toastr.error('Oops!', 'Could not update your location')
      throw new Error('Could not update location')
    }
  }
}

export const createNewConversation = (user1Id, user2Id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      const gqlClient = await graphqlClient()
      const conversationResponse = await gqlClient.request(
        GQL_CREATE_CONVERSATION,
        { created_at: JSON.stringify(new Date()) }
      )
      const conversation =
        conversationResponse.insert_conversations.returning[0]

      await gqlClient.request(GQL_CREATE_CONVERSATION_LINK, {
        user_id: user1Id,
        conversation_id: conversation.id
      })
      await gqlClient.request(GQL_CREATE_CONVERSATION_LINK, {
        user_id: user2Id,
        conversation_id: conversation.id
      })

      // get the updated profile....
      const conversationsResponse = await gqlClient.request(
        GQL_GET_PROFILE_CONVERSATIONS,
        { id: user1Id }
      )

      dispatch({
        type: CREATE_NEW_CONVERSATION,
        payload: {
          type: CREATE_NEW_CONVERSATION,
          data: conversationsResponse.profile[0]
        }
      })

      const mostRecentConvoLink =
        conversationsResponse.profile[0].conversation_links[
          conversationsResponse.profile[0].conversation_links.length - 1
        ]
      await dispatch(setActiveConversation(mostRecentConvoLink))

      dispatch(asyncActionFinish())
      return conversation.id
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
      toastr.error('Oops!', 'Could not create a new conversation')
      throw new Error('Could not create a new conversation')
    }
  }
}

export const blockUser = ({ user_id, blocked_user_id }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      const gqlClient = await graphqlClient()
      await gqlClient.request(GQL_BLOCK_USER, { user_id, blocked_user_id })

      const {blocked_users} = await gqlClient.request(GQL_BLOCKED_USERS, {
        user_id
      })

      dispatch({
        type: BLOCK_USER,
        payload: {
          type: BLOCK_USER,
          data: blocked_users
        }
      })

      toastr.success('Success!', 'User has now been blocked')
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
      toastr.error('Oops!', 'Could block user')
      throw new Error('Could not block user')
    }
  }
}

export const unblockUser = ({ user_id, blocked_user_id }) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart())
      const gqlClient = await graphqlClient()
      await gqlClient.request(GQL_UNBLOCK_USER, { user_id, blocked_user_id })

      const {blocked_users} = await gqlClient.request(GQL_BLOCKED_USERS, {
        user_id
      })

      dispatch({
        type: UNBLOCK_USER,
        payload: {
          type: UNBLOCK_USER,
          data: blocked_users
        }
      })

      toastr.success('Success!', 'User has now been unblocked')
      dispatch(asyncActionFinish())
    } catch (error) {
      console.log(error)
      dispatch(asyncActionError())
      toastr.error('Oops!', 'Could unblock user')
      throw new Error('Could not unblock user')
    }
  }
}

export const updateListingImages = ({ images, location_id }) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()

      const {
        insert_images_image: { returning }
      } = await gqlClient.request(GQL_ADD_IMAGES, { objects: images })
      dispatch({
        type: ADD_LOCATION_IMAGES,
        payload: { type: ADD_LOCATION_IMAGES, data: returning }
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      // console.log(error)
    }
  }
}

export const addLocationImages = (imageObjects) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      if (!imageObjects.length) {
        throw new Error('Please pass an array of image objects')
      }

      const gqlClient = await graphqlClient()
      const {
        insert_images_image: { returning }
      } = await gqlClient.request(GQL_ADD_IMAGES, { objects: imageObjects })
      dispatch({
        type: ADD_LOCATION_IMAGES,
        payload: { type: ADD_LOCATION_IMAGES, data: returning }
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
      // console.log(error)
    }
  }
}

export const removeLocationImage = (imageId) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart())
    try {
      const gqlClient = await graphqlClient()
      const {
        delete_images_image: { returning }
      } = await gqlClient.request(GQL_REMOVE_IMAGE, { id: imageId })

      dispatch({
        type: REMOVE_LOCATION_IMAGES,
        payload: {
          type: REMOVE_LOCATION_IMAGES,
          data: { id: returning[0].id, location_id: returning[0].location_id }
        }
      })
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError())
    }
  }
}

const removePrivateProfileFields = (profileObject) => {
  const removedFileds = [
    'cognito_sub',
    'date_joined',
    'id',
    'last_login',
    'locations',
    'default_location',
    'conversation_links'
  ]
  removedFileds.forEach((field) => delete profileObject[field])
  return profileObject
}

export const addListing = ({ listing }) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart())
      const gqlClient = await graphqlClient()
      const {
        insert_locations_location: { returning }
      } = await gqlClient.request(GQL_CREATE_LOCATION, {
        objects: { ...listing }
      })
      const newListing = returning[0]

      dispatch({
        type: CREATE_PROFILE_LOCATION,
        payload: { type: CREATE_PROFILE_LOCATION, data: newListing }
      })

      toastr.success('Success', 'Listing has been added to your profile')
      dispatch(asyncActionFinish())

      return newListing
    } catch (error) {
      dispatch(asyncActionError())
      toastr.error('Error', 'Listing could not be added')
    }
  }
}

const removePrivateLocationFields = (locationObject) => {
  const removedFileds = ['id', 'user_id', 'images']
  removedFileds.forEach((field) => delete locationObject[field])
  return locationObject
}

// TODO: Pull this in when needed
// const translateAttributes = (data) => {
//   const attributes = {};
//   data
//     .filter(attr => [ 'sub', 'email' ].includes(attr.Name))
//     .forEach(attr => attributes[ attr.Name ] = attr.Value);
//   return attributes;
// }

const modalHelper = (profile, dispatch) => {
  // https://lucid.app/lucidchart/f7d0e860-05c2-477b-a054-1be66251b7f0/edit?page=cweGjY9KDa8p#
  if (profile.show_welcome_prompt) {
    dispatch(openModal('WelcomePromptModal'))
  } else if (profile.show_donate_prompt && !isUIWebView) {
    dispatch(openModal('DonateModal'))
  } else if (!profile.default_location_id) {
    dispatch(openModal('ProfileModal'))
  } else {
    dispatch(closeModal())
  }
}
