import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import PaymentMessages from "./PaymentMessages";
import { closeModal } from "../modals/modalActions";

const PAYMENT_STATUSES = {
  LOADING: "loading",
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

const Payments = ({ paymentValue, setPayNow }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [stripeMessages, addStripeMessage] = useState(null);
  const profile = useSelector((state) => state.currentUser.profile);
  const [showCreditCardPayment, setShowCreditCardPayment] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null); // null | loading | pending | success | error

  useEffect(() => {
    if (!paymentValue || paymentValue <= 0) {
      setPayNow(false);
    }

    const asyncInit = async () => {
      setLoading(true);
      const intent = await API.post("stripe", "/payment-intent", {
        body: {
          amount: paymentValue,
        },
      });
      setLoading(false);

      const {
        paymentIntent: { client_secret },
      } = JSON.parse(intent.body);
      setClientSecret(client_secret);
    };

    asyncInit();
  }, [paymentValue, setPayNow]);

  useEffect(() => {
    if (stripe && clientSecret) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Donate to Shared Earth",
          amount: paymentValue,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on("paymentmethod", async (e) => {
        try {
          const intent = await API.post("stripe", "/payment-intent", {
            body: {
              amount: paymentValue,
              paymentMethodType: "card",
            },
          });
          const {
            paymentIntent: { client_secret },
          } = JSON.parse(intent.body);

          // confirm the payment intent on the client.
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            client_secret,
            {
              payment_method: e.paymentMethod.id,
            },
            {
              handleActions: false,
            }
          );

          if (error) {
            e.complete("fail");
            setPaymentStatus(PAYMENT_STATUSES.ERROR);
            addStripeMessage({
              message: `Payment Failed: ${error.message}`,
              type: "error",
            });
            return;
          }
          // APPLE PAY WAS A SUCCESS
          e.complete("success");
          setPaymentStatus(PAYMENT_STATUSES.SUCCESS);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          dispatch(closeModal());
          if (paymentIntent.status === "requires_action") {
            stripe.confirmCardPayment(client_secret);
          }
        } catch (error) {
          setPaymentStatus(PAYMENT_STATUSES.ERROR);
          addStripeMessage({
            message: `Payment Failed: ${error.message}`,
            type: "error",
          });
        }
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          // only shown true if using safari or ios
          setPaymentRequest(pr);
        }
      });
    }
  }, [clientSecret, stripe, paymentValue, setPayNow, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    addStripeMessage(null); // reset error messages
    setPaymentStatus(PAYMENT_STATUSES.PENDING);
    try {
      const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            email: profile.username,
          },
        },
      });
      setPaymentStatus(null);

      if (confirmPayment.error) {
        setPaymentStatus(PAYMENT_STATUSES.ERROR);
        addStripeMessage({
          message: `Payment Failed: ${confirmPayment.error.message}`,
          type: "error",
        });
        return;
      }

      if (confirmPayment.paymentIntent.status === "succeeded") {
        setPaymentStatus(PAYMENT_STATUSES.SUCCESS);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        dispatch(closeModal());
        return;
      }
    } catch (error) {
      setPaymentStatus(PAYMENT_STATUSES.ERROR);
      addStripeMessage({ message: "Something went wrong!", type: "error" });
    }
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : paymentStatus === PAYMENT_STATUSES.SUCCESS ? (
        <div className="text-center">
          <h2 className="text-primary">Success!</h2>
          <h4>Thank you for your donation</h4>
        </div>
      ) : (
        <>
          <PaymentMessages messages={stripeMessages} />
          <div className="pt-3">
            <h4 className="pb-2">Select Method of Donation</h4>
            <div className="d-flex justify-content-between">
              <button
                className={`btn btn-blue-light w-${
                  paymentRequest ? "50" : "100"
                } m-1`}
                onClick={() => setShowCreditCardPayment(!showCreditCardPayment)}
              >
                <i className="fas fa-credit-card"></i>&nbsp; Credit Card
              </button>
              {paymentRequest && (
                <PaymentRequestButtonElement
                  className="btn btn-apple w-50 m-1"
                  options={{ paymentRequest }}
                />
              )}
            </div>
            {showCreditCardPayment && (
              <form onSubmit={handleSubmit}>
                <div className="form-group mt-3">
                  <label>Credit or debit card</label>
                  <div id="card-element" className="form-control">
                    <CardElement />
                  </div>
                  {paymentStatus === "pending" ? (
                    <LoadingIndicator />
                  ) : (
                    <button
                      disabled={paymentStatus === "pending" || !stripe}
                      type="submit"
                      className="btn btn-blue-light w-50 mt-2"
                    >
                      Pay
                    </button>
                  )}
                </div>
              </form>
            )}
            <button
              className={`btn btn-gray-500 w-100 m-1`}
              onClick={() => setPayNow(false)}
            >
              <i className="fas"></i>&nbsp; Cancel
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Payments;
