import { gql } from 'graphql-request'

export const GQL_CREATE_CONVERSATION = gql`
  mutation createNewConversation($created_at: timestamptz!) {
    insert_conversations(objects: {created_at: $created_at}) {
      returning {
        id
      }
    }
  }`;

export const GQL_CREATE_CONVERSATION_LINK = gql`
  mutation createNewConversationLink($conversation_id: Int!, $user_id: Int!) {
    insert_conversation_link(objects: {conversation_id: $conversation_id, user_id: $user_id}) {
      returning {
        id
        user_id
      }
    }
  }`;

  export const GQL_UPDATE_CONVERSATION_LINK_LAST_READ = gql`
    mutation updateTimeStamp($id: Int!, $last_read_date: timestamptz!) {
      update_conversation_link(where: {id: {_eq: $id}}, _set: {last_read_date: $last_read_date}) {
        affected_rows
        returning {
          id
        user_id
        last_read_date
        }
    }
  }`;

export const GQL_CREATE_MESSAGE = gql`
  mutation createMessage($user_id: Int!, $conversation_id: Int!, $content: String) {
    insert_messages(objects: {author_id: $user_id, content: $content, conversation_id: $conversation_id}) {
      returning {
        id
      }
    }
  }`;