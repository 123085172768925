import { gql } from 'graphql-request'

// Get Blocked users by user ID
export const GQL_BLOCKED_USERS = gql`
  query blockedUsers($user_id: Int!) {
    blocked_users(where: { user_id: { _eq: $user_id } }) {
      user_id
      blocked_user_id
      blocked_user_profile {
        id
        avatar_url
        display_name
        first_name
        last_name
      }
    }
  }
`
