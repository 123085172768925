import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../../common/ui/LoadingComponent'

const AsyncResourcesDashboard = loadable(() => import('./ResourcesDashboard'), {
  loading: <LoadingComponent />
})

const clientRoutes = [
  {
    path: '/resources',
    component: AsyncResourcesDashboard
  }
]

export default clientRoutes
