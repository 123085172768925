import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import awsExports from "./aws-exports";
import createStore from './store/createStore'
import App from './containers/App'

// Application Styles
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import "react-medium-image-zoom/dist/styles.css"
import 'semantic-ui-css/semantic.min.css'
import './styles/main.scss'
import { createApolloClient } from './apollo/client';
import { ApolloProvider } from '@apollo/client';

// testing commit - 2

// override oauth response type from code to token
// awsExports.oauth.responseType = 'token'

// Fix for multiple oauth redirects
// See here: https://watilde.medium.com/how-to-support-multiple-redirect-urls-of-auth-with-amplify-7bffe5798047
awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;


// Amplify.configure({
//   ...awsExports,
//   "aws_user_files_s3_bucket": "sharedearth104600-sedev",
//   "aws_user_files_s3_bucket_region": "us-west-2"
// });
Amplify.configure(awsExports);

const initialState = window.___INITIAL_STATE__ || {}
const store = createStore(initialState)
const persistor = persistStore(store)

const apolloClient = createApolloClient()

ReactDOM.render(
  <ApolloProvider client={ apolloClient }>
    <PersistGate persistor={ persistor }>
      <Provider store={ store }>
        <App />
      </Provider>
    </PersistGate>
  </ApolloProvider>
  , document.getElementById('root')
)
