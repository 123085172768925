import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../common/currentUser/profileActions";
import useProfileImage from "../../common/currentUser/useProfileImage";
import LocationCard from "../../common/locations/LocationCard";
import { closeModal } from "../../common/modals/modalActions";

const DefaultLocationModalForm = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.currentUser.profile);
  const profileImage = useProfileImage({width: 100, height: 100})

  return (
    <>
      <div className="p-5">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <strong>Existing Information</strong>
              <p>Welcome back! Please select your default location</p>
            </div>
            <div className="avatar-wrapper avatar-lg mx-auto my-3">
              <div
                style={
                  profileImage && { backgroundImage: `url(${profileImage})` }
                }
                className="avatar bg-light"
              />
            </div>
            <h4 className="text-center">
              {userProfile.first_name && userProfile.first_name}{" "}
              {userProfile.last_name && userProfile.last_name}
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row row-cols-lg-3 row-cols-md-2 mt-4 gx-5">
              {userProfile &&
                userProfile.locations &&
                userProfile.locations.map((location) => {
                  return (
                    <LocationCard key={location.id} data={location}>
                      <div className={`card-footer bg-gray-500`}>
                        <a
                          href="!#"
                          className={`card-link text-white text-decoration-none ${location.is_enabled ? '' : 'disabled'}`}
                          onClick={async (e) => {
                            e.preventDefault();
                            if (!location.is_enabled) return;
                            try {
                              await dispatch(
                                updateProfile({
                                  default_location_id: location.id,
                                })
                              );
                              dispatch(closeModal());
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          Set Default
                        </a>
                      </div>
                    </LocationCard>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultLocationModalForm;
