import React from "react";

const AddressInputAutoCompleteContainer = ({ inputProps, suggestions, suggestionItemProps, loading, placeholder, showMap, status, required }) => {
  return (
    <>
      <input
        {...inputProps({
          placeholder: placeholder,
          className: `form-control ${status} form-control-address ${
            showMap ? "mb-3" : ""
          }`,
          required: required,
        })}
      />
      <div className="autocomplete-dropdown-container">
        {loading && <></>} {/*  TODO: Add loading if needed */}
        {suggestions.map((suggestion, i) => {
          const className = suggestion.active
            ? "suggestion-item--active"
            : "suggestion-item";
          return (
            <div
              key={suggestion.placeId}
              {...suggestionItemProps(suggestion, {
                className,
              })}
            >
              <span>{suggestion.description}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AddressInputAutoCompleteContainer;
