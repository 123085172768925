import React, { useRef } from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Modal, Carousel } from "react-bootstrap";
import { closeModal } from "./modalActions";
import haveIcon from "../../common/assets/icons/map-have-icon.svg";
import needIcon from "../../common/assets/icons/map-want-icon.svg";
import searchMapGraphic from "../../common/assets/illustrations/SE-welcome-modal-map-1.svg";
import filterMapGraphic from "../../common/assets/illustrations/SE-welcome-modal-map-2.jpg";
import { updateProfile } from "../currentUser/profileActions";
import logo from "../../common/assets/logo.svg";
import { useState } from "react";

const NUMBER_OF_CAROUSEL_SLIDES = 4
const WelcomePromptModal = () => {
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(1)
  const userProfile = useSelector((state) => state.currentUser.profile);
  const closeModalAction = async () => {
    if (userProfile && userProfile.show_welcome_prompt) {
      await dispatch(updateProfile({ show_welcome_prompt: false }));
    } else {
      dispatch(closeModal());
    }
  };

  const onNextClick = () => {
    if (slideIndex === NUMBER_OF_CAROUSEL_SLIDES) {
      closeModalAction()
      return;
    }
    carouselRef.current.next();
    setSlideIndex(slideIndex+ 1)
  };

  return (
    <>
      <Modal
        show
        id="modal-welcome"
        className="modal-full-width-sm"
        onHide={async () => {}}
        size="lg"
      >
        <Modal.Header className="border-0">
          <h2 className="d-block d-md-none w-100 text-center">Get Started</h2>
        </Modal.Header>

        <Carousel
          interval={null}
          indicators={false}
          controls={false}
          ref={carouselRef}
        >
          <Carousel.Item>
            <div className="item-wrapper">
              <div className="p-3 p-xl-4 welcome-icons">
                <h3 className="h1 text-center text-serif">
                  Find shared land or a gardener near you
                </h3>
                <div className="row justify-content-center align-items-center no-gutters-sm mt-4">
                  <div className="col-5 col-sm-4 col-xl-3 text-center">
                    <img
                      src={needIcon}
                      className="img-fluid"
                      alt="I need space icon"
                    />
                  </div>
                  <div className="col-2 text-center display-4 align-self-center d-none d-lg-block">
                    <i className="fas fa-arrows-alt-h"></i>
                  </div>
                  <div className="col-5 col-sm-4 col-xl-3 text-center">
                    <img
                      src={haveIcon}
                      className="img-fluid"
                      alt="I have Garden space icon"
                    />
                  </div>
                </div>
                <div className="row justify-content-center no-gutters-sm">
                  <div className="col-5 col-sm-4 col-xl-3 text-center">
                    <p className="lead mt-3">Gardeners</p>
                  </div>
                  <div className="col-2 d-none d-lg-block"></div>
                  <div className="col-5 col-sm-4 col-xl-3 text-center">
                    <p className="lead mt-3">Land Spaces</p>
                  </div>
                </div>
              </div>
            </div>
            
          </Carousel.Item>
          <Carousel.Item>
            <div className="item-wrapper">
              <div className="p-3 p-xl-4">
                <h3 className="h1 text-center text-serif mb-3 mb-lg-4">
                  Use the map to connect to users near you
                </h3>
                <img
                  className="img-fluid mx-auto d-block rounded"
                  src={searchMapGraphic}
                  alt="Search Map Graphic"
                />
              </div>
            </div>
            
          </Carousel.Item>
          <Carousel.Item>
            <div className="item-wrapper">
              <div className="p-3 p-xl-4">
                <h3 className="h1 text-center text-serif mb-3 mb-lg-4">
                  Filter your search by need or location
                </h3>
                <img
                  className="img-fluid d-block mx-auto rounded"
                  src={filterMapGraphic}
                  alt="Filter Map Graphic"
                />
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="item-wrapper">
              <div className="p-3 p-xl-4">
                <h3 className="h1 text-center text-serif mb-3 mb-lg-4">
                  Start growing your gardening community!
                </h3>
                <img
                  src={logo}
                  className="d-block img-fluid mx-auto my-3 w-50"
                  alt="Shared Earth logo"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="p-3">
          <button className="btn btn-blue-light btn-block" onClick={onNextClick}>
            Continue
          </button>
        </div>
        
      </Modal>
    </>
  );
};

// WelcomePromptModal.propTypes = {

// }

export default WelcomePromptModal;
