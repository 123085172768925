import { ADD_LOCATION_IMAGES, BLOCK_USER, CREATE_NEW_CONVERSATION, CREATE_PROFILE_LOCATION, LOAD_PROFILE, REMOVE_LOCATION_IMAGES, REMOVE_PROFILE, UNBLOCK_USER, UPDATE_PROFILE, UPDATE_PROFILE_LOCATION } from './profileConstants'
import { createReducer } from '../utils/reducerUtil';

const initialState = null

export function profile(state = {}, payload) {
  switch (payload.type) {
    case LOAD_PROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        ...payload.data
      }
    case REMOVE_PROFILE:
      return initialState
    default:
      return state;
  }
}

export function updateProfileLocation(state = {}, payload) {
  return {
    ...state,
    locations: state.locations.map(location => (location.id === payload.data.id) ? payload.data : location)
  }
}

export function createProfileLocation(state = {}, payload) {
  const locations = state.locations || []
  return {
    ...state,
    locations: [ ...locations, payload.data ]
  }
}

export function createNewConversation(state = {}, payload) {
  return {
    ...state,
    ...payload.data
  }
}

export function modifyLocationImages(state = {}, payload) {
  switch (payload.type) {
    case ADD_LOCATION_IMAGES:
      const locationsAdded = state.locations.map(location => {
        if (location.id === payload.data[ 0 ].location_id) {
          location.images = [ ...location.images, ...payload.data ]
        }
        return location
      })

      return {
        ...state,
        locations: locationsAdded
      }

    case REMOVE_LOCATION_IMAGES:
      const { id, location_id } = payload.data
      const locationsRemoved = state.locations.map(location => {
        // Find the location we're working with....
        if (location.id === location_id) {
          // remove the image
          location.images = location.images.filter(image => image.id !== id) || []
        }

        return location
      })

      return {
        ...state,
        locations: locationsRemoved
      }

    default:
      return state;
  }
}

export const blockUser = (state = {}, payload) => {
  return {
    ...state,
    blockedUsers: payload.data
  }
}

export const unblockUser = (state = {}, payload) => {
  return {
    ...state,
    blockedUsers: payload.data
  }
}

export default createReducer(initialState, {
  [ LOAD_PROFILE ]: profile,
  [ REMOVE_PROFILE ]: profile,
  [ UPDATE_PROFILE ]: profile,
  [ CREATE_NEW_CONVERSATION ]: createNewConversation,
  [ UPDATE_PROFILE_LOCATION ]: updateProfileLocation,
  [ CREATE_PROFILE_LOCATION ]: createProfileLocation,
  [ ADD_LOCATION_IMAGES ]: modifyLocationImages,
  [ REMOVE_LOCATION_IMAGES ]: modifyLocationImages,
  [ BLOCK_USER ]: blockUser,
  [ UNBLOCK_USER ]: unblockUser
})