import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import {
  addLocationToProfile,
  updateProfile
} from '../../common/currentUser/profileActions'
import {
  AddressInputMap,
  ListingTypeInput,
  SwitchInput,
  TextInput
} from '../../common/fields'
import { setActiveLocation } from '../../common/locations/locationsAction'
import { closeModal } from '../../common/modals/modalActions'

const ProfileModalForm = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.currentUser.profile)

  const validationSchema = Yup.object().shape({
    formatted_address: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    display_name: Yup.string()
      .max(50, 'Must be 50 characters or less')
      .matches(/^[^\s]*$/, 'No spaces are allowed')
      .required('Required')
  })

  const localStorageAdderss =
    localStorage.getItem('signUpAddress') &&
    JSON.parse(localStorage.getItem('signUpAddress'))

  const initialValues = {
    first_name: (currentUser && currentUser.first_name) || '',
    last_name: (currentUser && currentUser.last_name) || '',
    display_name: '',
    formatted_address:
      (currentUser &&
        currentUser.default_location &&
        currentUser.default_location.formatted_address) ||
      (localStorageAdderss && localStorageAdderss.formatted_address) ||
      null,
    location:
      (currentUser && currentUser.default_location) ||
      (localStorageAdderss && localStorageAdderss) ||
      null
  }

  const handleSubmit = async (values) => {
    if (!values.is_enabled) {
      values.is_enabled = false
    }
    try {
      // create object with profile properties
      const profileUpdates = {
        // first_name: values.first_name,
        // last_name: values.last_name
        display_name: values.display_name
      }

      // Make a deep copy of values, we need location within the main object (parent level)
      const location = {
        user_id: currentUser.id,
        ...values,
        ...values.location
      }
      delete location.location // Because spread operator on location is used above, remove it from the initial values

      // delete first and last name from locations
      delete location.first_name
      delete location.last_name
      delete location.display_name

      const gqlLocation = await dispatch(addLocationToProfile(location))
      await dispatch(
        updateProfile({
          ...profileUpdates,
          default_location_id: gqlLocation.id
        })
      )
      await dispatch(setActiveLocation(gqlLocation))

      // Clear out address from local storage
      localStorage.removeItem('signUpAddress')

      dispatch(closeModal())
    } catch (error) {
      // surpress
    }
  }

  return (
    <>
      <div className='modal-header px-3 py-4 border-0'>
        <div className='w-100 text-center'>
          <h2 className='text-center'>Create Profile</h2>
          {/* <p>
            We will never share your address without your permission. View our{' '}
            <a href='/privacy-policy' className='text-dark text-underline'>
              privacy policy
            </a>
          </p> */}
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({ values, setFieldValue, dirty, errors, submitCount }) => (
          <Form id='complete-profile-form'>
            <div className='p-3'>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <Field
                    autoComplete='off'
                    name='display_name'
                    type='text'
                    label='Display Name'
                    component={TextInput}
                    className=''
                    onChange={(value) => setFieldValue('display_name', value)}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Field
                    name='formatted_address'
                    type='text'
                    component={AddressInputMap}
                    defaultLocation={values.location}
                    className='position-relative'
                    label='Add Location'
                    onChange={(value) => {
                      setFieldValue('formatted_address', value)
                    }}
                  />
                </div>
              </div>
              <div className='row mt-3 justify-content-center'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-12'>
                      <h3>Choose Your Profile Type</h3>
                    </div>
                  </div>

                  <div className='row'>
                    {/* TODO Set Error message if radios are empty */}

                    <div className='col-12 mb-3 text-center'>
                      <Field
                        name='type'
                        type='text'
                        component={ListingTypeInput}
                      />
                      <Field
                        name={`is_enabled`}
                        type='checkbox'
                        component={SwitchInput}
                        onChange={(e, value) =>
                          setFieldValue('is_enabled', value.checked)
                        }
                        label='Share my listings'
                      />
                      <button
                        type='submit'
                        form='complete-profile-form'
                        className={`btn btn-save px-lg-5 ${
                          dirty ? 'btn-blue-light' : 'btn-gray-500 btn-disabled'
                        }`}
                        disabled={
                          dirty ? false : true || Object.keys(errors).length > 0
                        }
                      >
                        Create Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <FormikDebug /> */}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ProfileModalForm
