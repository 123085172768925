import React from "react";
// import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import DefaultLocationModalForm from "../../modules/profile/DefaultLocationModalForm";
import ProfileModalForm from "../../modules/profile/ProfileModalForm";

const ProfileModal = ({ heading }) => {
  const userProfile = useSelector((state) => state.currentUser.profile);

  return (
    <>
      <Modal
        show 
        className="modal-full-width-sm"
        onHide={() => {}} size="lg">
        {userProfile && userProfile.locations && userProfile.locations.length >= 2 ? (
          <DefaultLocationModalForm />
        ) : (
          <ProfileModalForm />
        )}
      </Modal>
    </>
  );
};

// ProfileModal.propTypes = {

// }

export default ProfileModal;
