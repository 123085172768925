import React from 'react'
import PropTypes from 'prop-types'

const LoadingIndicator = ({ message }) => {

    return (
      <div className="bg-fill text-center">
        <div>
          <div className="loading-indicator">
            <div className="spinning">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          <p>{message}</p>
        </div>
      </div>
    )
}

LoadingIndicator.propTypes = {
  message: PropTypes.string
}


export default LoadingIndicator