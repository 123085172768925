import { createReducer } from '../utils/reducerUtil';
import { HIDE_CONVERSATION_WINDOW, REMOVE_CONVERSATIONS, SET_ACTIVE_CONVERSATION } from './conversationConstants';

const initialState = {
  activeConversation: null,
  shouldHideConversationWindow: true  // by default no chat will be selected, no point in showing the window
}

export const setActiveConversation = (state={}, payload) => {
  return {
    ...state,
    activeConversation: {...payload.data}
  }
}

export const shouldHideConversationWindow = (state={}, payload) => {
  return {
    ...state,
    shouldHideConversationWindow: payload.data
  }
}

export function removeConversations(state = {}, payload) {
  return initialState
}

export default createReducer(initialState, {
  [ SET_ACTIVE_CONVERSATION ]: setActiveConversation,
  [ HIDE_CONVERSATION_WINDOW ]: shouldHideConversationWindow,
  [ REMOVE_CONVERSATIONS ]: removeConversations,
})