import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import ProfileDetails from '../../modules/profile/ProfileDetails'
import ConnectButton from '../conversations/ConnectButton'
import { closeDrawer } from './drawerActions'
import { getProfileById } from '../currentUser/profileActions'
import LoadingIndicator from '../ui/LoadingIndicator'
import BlockUserButton from '../ui/BlockUserButton'

const ProfileDrawer = ({ pId, type, locId }) => {
  const dispatch = useDispatch()
  const { profileId, locationId, locationType } = useSelector(
    (state) => state.router.location.query
  )

  const [profile, setProfile] = useState(null)

  // Load profile on initial load
  useEffect(() => {
    const asyncInit = async () => {
      try {
        const p = await dispatch(getProfileById(pId || profileId))
        setProfile(p)
      } catch (error) {
        console.log(error.message)
      }
    }

    asyncInit()
    /* eslint-disable */
  }, [])

  if (!profile) {
    return <LoadingIndicator />
  }

  return (
    <>
      <section className='p-md-4 pt-5'>
        <div className='container-fluid'>
          <ProfileDetails
            locationId={parseInt(locId || locationId, 10)}
            profile={profile}
            locationType={type || locationType}
          />
        </div>
      </section>
      <section id='cta-connect' className='p-4'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='my-5'>
                <ConnectButton
                  connectedUser={profile}
                  className='btn btn-block btn-lg btn-blue-light'
                  locationType={type || locationType}
                  onClick={() => dispatch(closeDrawer())}
                >
                  <p>
                    Message {profile.display_name || profile.first_name} to get
                    started!
                  </p>
                </ConnectButton>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <BlockUserButton userToBlockId={profile.id} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

ProfileDrawer.propTypes = {
  locationId: PropTypes.number,
  profile: PropTypes.instanceOf(Object)
}

export default ProfileDrawer
