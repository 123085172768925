import React from "react";
import { useDispatch } from "react-redux";
import { history } from "../utils/history";
import { userAuthSignOut } from "./authActions";

const SignoutButton = () => {
  const dispatch = useDispatch();
  return (
    <>
      <button
        className="btn btn-outline-blue-light"
        onClick={() => {
          dispatch(userAuthSignOut());
          history.push("/");
        }}
      >
        Sign Out
      </button>
    </>
  );
};

export default SignoutButton;
