import { GET_ALL_GEO_LOCATIONS, REMOVE_LOCATIONS, SET_ACTIVE_LOCATION, SET_USER_TYPE_FILTER } from './locationsConstant';
import { createReducer } from '../utils/reducerUtil';

const initialState = {
  activeLocation: {},
  // userLocations: [], // TODO: Remove this
  allLocations: [],
  userTypeFilter: 'everyone'
};

// export function getLocations(state = {}, payload) {
//   switch (payload.type) {
//     case GET_LOCATIONS:
//       const newLocations = payload.data;
//       const ids = new Set(newLocations.map(e => e.id))
//       const updatedLocations = state.userLocations.filter(existingLocation => !ids.has(existingLocation.id)).concat(newLocations)

//       return { ...state, userLocations: updatedLocations }

//     default:
//       return state;
//   }
// }

export function getLocations(state = {}, payload) {
  switch( payload.type ) {
    case GET_ALL_GEO_LOCATIONS:
      return { ...state, allLocations: payload.data }
    default:
      return state;
  }
}

export function setActiveLocation(state = {}, payload) {
  return { ...state, activeLocation: payload.data }
}

export function setUserTypeFilter(state = {}, payload) {
  return { ...state, userTypeFilter: payload.data }
}

export function removeLocations(state = {}, payload) {
  return initialState
}

export default createReducer(initialState, {
  [ GET_ALL_GEO_LOCATIONS ]: getLocations,
  [ SET_ACTIVE_LOCATION ]: setActiveLocation,
  [ SET_USER_TYPE_FILTER ]: setUserTypeFilter,
  [ REMOVE_LOCATIONS ]: removeLocations
})