/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
// import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TestModal from './TestModal'
import LoginModal from './auth/LoginModal'
import AuthCodeModal from './auth/AuthCodeModal'
import SignupModal from './auth/SignupModal'
import ResetPasswordRequestModal from './auth/ResetPasswordRequestModal'
import ResetPasswordModal from './auth/ResetPasswordModal'
import VideoModal from './VideoModal'
import WelcomePromptModal from './WelcomePromptModal'
import NewLocationModal from './NewLocationModal'
import DonateModal from './DonateModal'
import ProfileModal from './ProfileModal'
import ConfirmDeleteAccountModal from './ConfirmDeleteAccountModal'


const modalLookup = {
  TestModal,
  LoginModal,
  SignupModal,
  ResetPasswordModal,
  AuthCodeModal,
  ResetPasswordRequestModal,
  VideoModal,
  WelcomePromptModal,
  NewLocationModal,
  DonateModal,
  ProfileModal,
  ConfirmDeleteAccountModal
}

const ModalWrapper = () => {
  const currentModal = useSelector(state => state.modals)

  if (currentModal) {
    const { modalType, modalProps } = currentModal
    const ModalComponent = modalLookup[modalType]
    return <ModalComponent {...modalProps} />
  }
  return <></>
}

ModalWrapper.propTypes = {

}

export default ModalWrapper
