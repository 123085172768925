import React from 'react'
import PropTypes from 'prop-types'
import { Field } from "formik"
import {
  SelectInput
} from "../fields"

import {
  experienceOptions,
  searchDistanceOptions,
  responsibilitiesOptions,
  // interestedGrowingOptions
} from "../../common/data/selects"

const NeedFields = ({ setFieldValue }) => {
  return (
    <>
      <Field
        name="search_distance"
        type="text"
        component={SelectInput}
        options={searchDistanceOptions}
        onChange={({ value }) => setFieldValue('search_distance', value)}
        label="How far are you looking?"
      />

      <Field
        name="experience"
        type="text"
        component={SelectInput}
        options={experienceOptions}
        onChange={({ value }) => setFieldValue('search_distance', value)}
        label="How long have you been gardening?"
      />

      <Field
        name="responsibilities"
        type="text"
        component={SelectInput}
        options={responsibilitiesOptions}
        onChange={({ value }) => setFieldValue('responsibilities', value)}
        label="What type of arrangement are you looking for?"
      />

      {/* TB: Hiding until support for array is added. #138 */}
      {/* <Field
        name="interested_growing"
        type="text"
        component={SelectInput}
        options={interestedGrowingOptions}
        isMulti
        isSearchable
        onChange={(value) => setFieldValue('interested_growing', value.map(({value}) => value))}
        label="What are you interested in growing?"
      /> */}
    </>
  )
}

NeedFields.propTypes = {
  setFieldValue: PropTypes.instanceOf(Function)
}

export default NeedFields
