import React from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { closeNotification } from "./notificationActions";
import { history } from "../utils/history";

const ProfileNotification = () => {
  const dispatch = useDispatch();
  const path = useSelector((state) =>
    state.router.location.pathname.replace(/\//, "")
  )

  return (
    <>
      <Alert
        className={`alert-global ${path ? `notification-${path}` : 'notification-home'}`}
        variant="info"
        onClose={() => dispatch(closeNotification())}
        dismissible
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Alert.Heading>Your profile is incomplete</Alert.Heading>
              <small>
                To update your profile, please go{" "}
                <a
                  href="!#"
                  className="text-white text-underline"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/profile");
                  }}
                >
                  here
                </a>
              </small>
            </div>
          </div>
        </div>
      </Alert>
    </>
  );
};

// ProfileNotification.propTypes = {

// }

export default ProfileNotification;
