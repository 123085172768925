import { gql } from 'graphql-request'

export const GQL_FRAG_PROFILE_FIELDS = gql`
  fragment ProfileFields on profile {
    about
    avatar_url
    cognito_sub
    date_joined
    first_name
    id
    last_login
    last_name
    username
    display_name
    default_location_id
    show_welcome_prompt
    show_donate_prompt
    email_on_resources
    email_on_new_matches
    email_on_messages
    email_on_announcements
    share_locations
    archived_date
    conversation_links {
      id
      last_read_date
      conversation {
        id
        messages {
          id
          author_id
          created_at
          content
        }
        conversation_links {
          id
          created_at
          last_read_date
          profile {
            id
            first_name
            last_name
            avatar_url
            default_location_id
            locations {
              id
              user_id
              # latitude
              # longitude
              about
              responsibilities # This gives an ID that will need to be mapped
              experience # This gives an ID that will need to be mapped
              compensation # This gives an ID that will need to be mapped
              garden_size # This gives an ID that will need to be mapped
              garden_status # This gives an ID that will need to be mapped
              search_distance # This gives an ID that will need to be mapped
              interested_growing # String value that is json encoded, it needs to be decoded
              type # This gives an ID that will need to be mapped
              formatted_address
              street_name
              street_number
              city
              zip
              state
              country
              geom
              is_enabled
            }
          }
        }
      }
    }
  }`;