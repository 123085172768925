import React from 'react'
import loadable from '@loadable/component'
import LoadingComponent from '../../common/ui/LoadingComponent'

const AsyncChatWrapper = loadable(() => import('./ChatDashboard'), {
  loading: <LoadingComponent />
})

const clientRoutes = [
  {
    path: '/messages',
    component: AsyncChatWrapper
  }
]

export default clientRoutes
