import React from 'react'
import PropTypes from 'prop-types'
import ListingForm from '../forms/ListingForm'
import { useSelector } from 'react-redux'
import AvatarIcon from '../ui/AvatarIcon'
import BlockUserButton from '../ui/BlockUserButton'

const BlockedUserDrawer = ({ listing }) => {
  const currentProfile = useSelector((state) => state.currentUser.profile)
  return (
    <>
      <section className='p-md-4 pt-5'>
        <div className='container-fluid'>
          {currentProfile.blockedUsers.length > 0 && currentProfile.blockedUsers.map(({ blocked_user_profile }) => {
            return (
              <>
                <div className='row align-items-md-center py-5  py-md-3 py-lg-5'>
                  <div className='col-4 col-md-1'>
                    <AvatarIcon
                      type={'need'}
                      className={'icon-map'}
                      // profile={blocked_user_profile}  // hiding this property
                    />
                  </div>
                  <div className='col-8 col-md-3'>
                    <h4 className='mb-0 fw-bold'>
                      {blocked_user_profile.display_name
                        ? blocked_user_profile.display_name
                        : `${blocked_user_profile.first_name} ${blocked_user_profile.last_name}`}
                    </h4>
                  </div>
                  <div className='col-12 col-md-3 text-md-right'>
                      <BlockUserButton userToBlockId={blocked_user_profile.id} />
                  </div>
                </div>
                <hr className='my-0 border-light' />
              </>
            )
          })}
          {currentProfile.blockedUsers.length <= 0 && (
            <div className='row justify-content-center'>
            <div className='col-12 col-lg-8'>
              <div className='bg-light p-5 text-center rounded'>
                <h2 className='mb-4'>
                  There are no blocked users
                </h2>
              </div>
            </div>
          </div>
          )}
        </div>
      </section>
    </>
  )
}

BlockedUserDrawer.propTypes = {
  location: PropTypes.instanceOf(Object),
  firstName: PropTypes.string
}

export default BlockedUserDrawer
