import React from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { closeModal } from './modalActions'
import { updateProfile } from '../currentUser/profileActions'

const ConfirmDeleteAccountModal = ({ heading }) => {
  const dispatch = useDispatch()

  return (
    <>
      <Modal show onHide={() => dispatch(closeModal())}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row justify-content-center mt-4'>
              <div className='col-12 col-lg-7'>
                <div className='row justify-content-center justify-content-lg-start auth-buttons'>
                  <div className='col-12 mb-2'>
                    <p>
                      Are you sure you want to delete your account? You will
                      have 30 days to revert this change
                    </p>
                    <button
                      className='btn btn-danger btn-block'
                      onClick={async () => {
                        await dispatch(updateProfile({ archived_date: new Date() }))
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => dispatch(closeModal())}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// ConfirmDeleteAccountModal.propTypes = {

// }

export default ConfirmDeleteAccountModal
