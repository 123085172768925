import React from 'react'
import { blockUser, unblockUser } from '../currentUser/profileActions'
import { useDispatch, useSelector } from 'react-redux'
// import PropTypes from 'prop-types'

// ----------------------------------------------------------------------
// BlockUserButton.propTypes = {
//   children: PropTypes.node
// }

export default function BlockUserButton({ userToBlockId }) {
  const currentUser = useSelector((state) => state.currentUser.profile)

  const isUserBlocked = currentUser?.blockedUsers?.find(
    (u) => u.blocked_user_id === userToBlockId
  ) || null

  const dispatch = useDispatch()

  if (!currentUser) {
    return
  }

  const handleBlockUser = async () => {
    await dispatch(
      blockUser({ user_id: currentUser.id, blocked_user_id: userToBlockId })
    )
  }

  const handleUnBlockUser = async () => {
    await dispatch(
      unblockUser({ user_id: currentUser.id, blocked_user_id: userToBlockId })
    )
  }
  return (
    <>
      <button
        className='btn btn-block btn-lg btn-danger'
        onClick={isUserBlocked ? handleUnBlockUser : handleBlockUser}
        // disabled={!currentUser}
      >
        {isUserBlocked ? 'Unblock' : 'Block'}
      </button>
    </>
  )
}
