import React from "react"
import PropTypes from 'prop-types'
import ListingForm from "../forms/ListingForm"

const ListingDrawer = ({ listing }) => {


  return (
    <>
      <section className="p-md-4 pt-5">
        <div className="container-fluid">
          <ListingForm listing={listing} />
        </div>
      </section>
    </>
  )
}

ListingDrawer.propTypes = {
  location: PropTypes.instanceOf(Object),
  firstName: PropTypes.string
}

export default ListingDrawer
