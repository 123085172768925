import { NOTIFICATION_CLOSE, NOTIFICATION_OPEN } from './notificationConstants'

export const openNotification = (notificationType, notificationProps) => {
  return {
    type: NOTIFICATION_OPEN,
    payload: {
      notificationType,
      notificationProps
    }
  }
}

export const closeNotification = () => {
  return {
    type: NOTIFICATION_CLOSE
  }
}