import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { closeModal } from '../modalActions'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../fields'
import { authUserSignUp, userAuthSocialSignIn } from '../../auth/authActions'
import { history } from '../../utils/history'
import ErrorBanner from '../../ui/ErrorBanner'
import logoSE from '../../../common/assets/shared-earth-vertical.svg'
import { getAuthCodeError } from '../../auth/authErrorCodes'
import { isUIWebView } from '../../utils/helpers'

const SignupModal = ({ heading }) => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState()

  const initialValues = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  const handleSubmit = async (values, resetForm) => {
    const { email, password, confirmPassword } = values

    if (password !== confirmPassword) {
      setErrorMessage(getAuthCodeError('PasswordMissMatch'))
      return
    }

    try {
      await dispatch(authUserSignUp({ username: email, password }))
      // history.push(`?showAuthCodeModal=true&username=${email}`); // Disabled because of this ticket: https://github.com/ocupop/shared-earth/issues/174
      history.push('/map')
      history.go()
    } catch (error) {
      setErrorMessage(getAuthCodeError(error.name))
    }
  }

  return (
    <>
      <Modal
        className='signup-modal modal-full-width-sm auth-modal'
        show
        onHide={() => dispatch(closeModal())}
      >
        <Modal.Header className='bg-light'>
          <button className='close' onClick={() => dispatch(closeModal())}>
            <i className='fas fa-long-arrow-alt-left d-block d-md-none'></i>
            <span className='d-none d-md-block'>&times;</span>
          </button>
          <img
            src={logoSE}
            className='img-fluid mx-auto py-4 d-none d-lg-block'
            alt='Shared Earth logo'
          />
          <h2 className='text-center w-100 d-block d-lg-none mb-0'>Sign Up</h2>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row justify-content-center mt-4'>
              <div className='col-12 col-lg-7'>
                <div className='row justify-content-center justify-content-lg-start auth-buttons'>
                  <div className='col-12 mb-2'>
                    <button
                      type='button'
                      onClick={() =>
                        userAuthSocialSignIn({ provider: 'Facebook' })
                      }
                      className='btn btn-block btn-blue-dark btn-icon justify-content-center justify-content-lg-start'
                    >
                      <div>
                        <span className='icon-wrapper'>
                          <i className='fab fa-facebook btn-icon' />
                        </span>
                        <span className='d-none d-lg-inline-block'>
                          Sign Up With&nbsp;
                        </span>
                        Facebook
                      </div>
                    </button>
                  </div>
                  {!isUIWebView && (
                    <div className='col-12 mb-2'>
                      <button
                        onClick={() =>
                          userAuthSocialSignIn({ provider: 'Google' })
                        }
                        className='btn btn-block btn-red-dark btn-icon justify-content-center justify-content-lg-start'
                      >
                        <div>
                          <span className='icon-wrapper'>
                            <i className='fab fa-google' />
                          </span>
                          <span className='d-none d-lg-inline-block'>
                            Sign Up With&nbsp;
                          </span>
                          Google
                        </div>
                      </button>
                    </div>
                  )}
                  <div className='col-12 mb-2'>
                    <button
                      onClick={() =>
                        userAuthSocialSignIn({ provider: 'SignInWithApple' })
                      }
                      className='btn btn-block btn-dark btn-icon justify-content-center justify-content-lg-start'
                    >
                      <div>
                        <span className='icon-wrapper'>
                          <i className='fab fa-apple' />
                        </span>
                        <span className='d-none d-lg-inline-block'>
                          Sign Up With&nbsp;
                        </span>
                        Apple
                      </div>
                    </button>
                  </div>
                </div>
                <hr className='mt-5' />
                <p className='text-center mt-4'>Or sign up with email</p>
                <ErrorBanner errorMessage={errorMessage} />
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { resetForm }) =>
                    handleSubmit(values, resetForm)
                  }
                >
                  {({ values, setFieldValue, dirty }) => (
                    <Form id='profile-form' className='mt-4'>
                      <div className='form-group'>
                        <Field
                          type='email'
                          name='email'
                          className='input-icon input-email'
                          component={TextInput}
                          placeholder='Email'
                        />
                      </div>
                      <div className='form-group'>
                        <Field
                          name='password'
                          type='password'
                          className='input-icon input-password'
                          placeholder='Password'
                          component={TextInput}
                          onChange={(value) =>
                            setFieldValue('password.newPassword', value)
                          }
                        />
                        <Field
                          name='confirmPassword'
                          type='password'
                          placeholder='Confirm Password'
                          className='input-icon input-password'
                          component={TextInput}
                          onChange={(value) =>
                            setFieldValue('confirmPassword', value)
                          }
                        />
                      </div>
                      <div className='form-group pt-3 mb-1'>
                        <button
                          type='submit'
                          form='profile-form'
                          className={`btn btn-save btn-block ${
                            dirty
                              ? 'btn-blue-light'
                              : 'btn-gray-500 btn-disabled'
                          }`}
                          disabled={dirty ? false : true}
                        >
                          Sign Up
                        </button>
                      </div>
                      {/* <FormikDebug /> */}
                    </Form>
                  )}
                </Formik>
                {/* <p className='text-center text-gray-500'>
                  <small>
                    We will never share your address without your permission.
                    &nbsp; By signing up, you agree to Shared Earth’s{' '}
                    <a href='/terms-of-use'>Terms of Use</a> and{' '}
                    <a href='/privacy-policy'>Privacy Policy</a>.
                  </small>
                </p> */}
                <p className='text-center text-gray-500 mt-5'>
                  <small>
                    Already have an account?{' '}
                    <a
                      href='#!'
                      onClick={(e) => {
                        e.preventDefault()
                        history.push('?showLoginModal=true')
                      }}
                    >
                      Login!
                    </a>
                  </small>
                </p>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  )
}

// SignupModal.propTypes = {

// }

export default SignupModal
