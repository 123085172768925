import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import RangeSlider from "react-bootstrap-range-slider";
import Payments from "./Payments";

const DonateFormStripe = () => {
  const [paymentValue, setPaymentValue] = useState(10);
  const [payNow, setPayNow] = useState(false);

  return (
    <>
      <div>
        <div>
          <h4>Select Donation Amount</h4>
          <Form className="mt-4">
            <Form.Group controlId="donateAmount" className="row">
              <div className="col-9">
                <RangeSlider
                  disabled={payNow}
                  value={paymentValue}
                  min={1}
                  max={500}
                  onChange={(changeEvent) =>
                    setPaymentValue(changeEvent.target.value)
                  }
                  variant="success"
                />
              </div>
              <div className="col-3 donate-total">
                <Form.Control
                  type="number"
                  disabled={payNow}
                  placeholder={paymentValue}
                  value={paymentValue}
                  min={1}
                  max={500}
                  onChange={(changeEvent) =>
                    setPaymentValue(changeEvent.target.value)
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="pt-3">
          {!payNow && (<button
            className={`btn btn-blue-light w-100 m-1`}
            onClick={() => setPayNow(true)}
          >
            <i className="fas"></i>&nbsp; Pay Now
          </button>)}
        </div>
        {payNow && (<Payments setPayNow={setPayNow} paymentValue={parseInt(paymentValue, 10) * 100} />)}
      </div>
    </>
  );
};

export default DonateFormStripe;
