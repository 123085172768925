import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as toastrReducer } from 'react-redux-toastr'
import asyncReducer from '../common/async/asyncReducer'
import modalReducer from '../common/modals/modalReducer'
import drawerReducer from '../common/drawers/drawerReducer'
import uiReducer from '../common/ui/uiReducers'
import authReducer from '../common/auth/authReducers'
import profileReducer from '../common/currentUser/profileReducer'
import locationsReducer from '../common/locations/locationsReducer'
import conversationReducers from '../common/conversations/conversationReducers'
import notificationReducer from '../common/notifications/notificationReducer'

const currentUserReducers = combineReducers({
  auth: authReducer,
  profile: profileReducer,
})

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    toastr: toastrReducer,
    async: asyncReducer,
    currentUser: currentUserReducers,
    locations: locationsReducer,
    conversations: conversationReducers,
    modals: modalReducer,
    drawers: drawerReducer,
    ui: uiReducer,
    notifications: notificationReducer
  })

export default rootReducer

/*
currentUsers:{...}
locations: {
  
  userLocations: [{...}]
}
TODO: Remove ui.locationFilters
*/
