import { createTransform } from 'redux-persist';

export const locationTransforms = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // console.log("@DEBUG::08162021-021101 - inboundState");
    // console.log(inboundState)
    // console.groupEnd()

    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // console.group("@DEBUG::08162021-034904 - outboundState")
    // console.log(outboundState)
    // console.groupEnd()

    return { ...outboundState }
  },
  // define which reducers this transform gets called for.
  { whitelist: [ 'locations' ] }
)