import { useEffect} from "react";
import { useSelector } from "react-redux";
import ReactGAU from "react-ga";
import ReactGA4 from "react-ga4";


const usePageTracking = () => {
  const location = useSelector((state) => state.router.location);
  const modal = useSelector(state => state.modals);

  ReactGAU.initialize(process.env.REACT_APP_GAU, { debug: false });
  ReactGA4.initialize(process.env.REACT_APP_GA4, { debug: false });

  // Track Pages
  useEffect(() => {
    ReactGAU.pageview(location.pathname + location.search);
    ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  // Track Modals
  useEffect(() => {
    if (modal) {
      ReactGAU.event({
        category: 'Modal Open',
        action: modal.modalType
      });

      ReactGA4.event({
        category: 'Modal Open',
        action: modal.modalType
      });
    }
  }, [modal])
};

export default usePageTracking;