import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import ReactGA4 from 'react-ga4'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../common/modals/modalActions'
import { history } from '../common/utils/history'
import logoSE from '../common/assets/shared-earth-horizontal.svg'
import saLogo from '../common/assets/Sustainable-America-Alternate-Logo.svg'
import { isUIWebView } from '../common/utils/helpers'

const MobileHomeNav = () => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const isLoggedIn = useSelector((state) =>
    state.currentUser.auth && state.currentUser.profile ? true : false
  )

  return (
    <div className='d-block d-lg-none'>
      <Navbar collapseOnSelect expand='lg'>
        <Navbar.Brand href='/'>
          <img src={logoSE} className='img-fluid' alt='Shared Earth logo' />
        </Navbar.Brand>
        <button
          className={`navbar-toggler ${expanded ? '' : 'collapsed'}`}
          onClick={() => setExpanded(!expanded)}
          aria-controls='mobile-homenav'
        >
          <span className='navbar-toggler-icon'>
            <div className='bar1'></div>
            <div className='bar2'></div>
            <div className='bar3'></div>
          </span>
        </button>
        <div
          className={`home-nav-drawer ${expanded ? 'show' : ''} `}
          id='mobile-homenav'
        >
          <div className='d-flex justify-content-center mt-3'>
            <ul className='nav mobile-secondary-nav flex-column'>
              <li className='nav-item border-bottom border-mid'>
                <a
                  href='/#how-it-works'
                  className='nav-link'
                  onClick={() => setExpanded(false)}
                >
                  <i className='fas fa-info-circle'></i>
                  How it works
                </a>
              </li>
              <li className='nav-item'>
                <button
                  onClick={() => {
                    ReactGA4.event({
                      category: 'Manual Donation Modal Event',
                      action: 'Donation Modal Open'
                    })
                    if (!isUIWebView) {
                      history.push('?showDonationModal=true')
                      dispatch(openModal('DonateModal'))
                    } else {
                      const a = document.createElement('a')
                      a.setAttribute('href', 'https://sharedearth.com/donate')
                      a.setAttribute('target', '_blank')

                      const dispatch = document.createEvent('HTMLEvents')
                      dispatch.initEvent('click', true, true)
                      a.dispatchEvent(dispatch)
                    }
                  }}
                  className='nav-link bg-transparent'
                >
                  <i className='icon-custom icon-donate' />
                  <span>Donate</span>
                </button>
              </li>
              <li className='nav-item border-bottom border-mid'>
                {!isUIWebView ? (
                  <a
                    href={process.env.REACT_APP_SHOPIFY_URL}
                    className='nav-link'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <i className='icon-custom icon-merchandise' />
                    <span>Merchandise</span>
                  </a>
                ) : (
                  <button
                    onClick={() => {
                      console.log(process.env.REACT_APP_SHOPIFY_URL)
                      const a = document.createElement('a')
                      a.setAttribute('href', process.env.REACT_APP_SHOPIFY_URL)
                      a.setAttribute('target', '_blank')

                      const dispatch = document.createEvent('HTMLEvents')
                      dispatch.initEvent('click', true, true)
                      a.dispatchEvent(dispatch)
                    }}
                    className='nav-link bg-transparent'
                  >
                    <i className='icon-custom icon-merchandise' />
                    <span>Merchandise</span>
                  </button>
                )}
              </li>
            </ul>
          </div>
          <div className='bg-light h-100 d-flex justify-content-center py-5 mt-3'>
            <ul id='mobile-home-auth-buttons' className='nav flex-column'>
              {isLoggedIn ? (
                <li className='nav-item'>
                  <button
                    className='btn btn btn-blue-light btn-block'
                    onClick={() => {
                      history.push('/map')
                    }}
                  >
                    Goto App
                  </button>
                </li>
              ) : (
                <>
                  <li className='nav-item mb-3'>
                    <button
                      className='btn btn-outline-blue-light bg-white btn-block'
                      onClick={() => {
                        dispatch(openModal('LoginModal'))
                        history.push('?showLoginModal=true')
                      }}
                    >
                      Login
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className='btn btn-blue-light btn-block'
                      onClick={() => {
                        dispatch(openModal('SignupModal'))
                        history.push('?showSignupModal=true')
                      }}
                    >
                      Sign Up
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
          <footer className='footer-mobile-nav'>
            <div className='row align-items-center justify-content-center no-gutters'>
              <div className='col-6 border-right border-white'>
                <p className='mx-3 text-uppercase text-white text-center'>
                  <small>Presented By</small>
                </p>
              </div>
              <div className='col-6'>
                <img
                  className='mx-3 img-fluid'
                  src={saLogo}
                  alt='shared-earth'
                />
              </div>
            </div>
          </footer>
        </div>
      </Navbar>
    </div>
  )
}

export default MobileHomeNav
