import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeModal } from "../modalActions";
import { Field, Form, Formik } from "formik";
import { history } from "../../utils/history";
import { authForgotPasswordGetCode } from "../../auth/authActions";
import ErrorBanner from "../../ui/ErrorBanner";
import logoSE from "../../../common/assets/shared-earth-vertical.svg";
import { getAuthCodeError } from "../../auth/authErrorCodes";

const ResetPasswordRequestModal = ({ heading }) => {
  const dispatch = useDispatch();
  const authModal = useSelector((state) => state.router.location.query);
  const initialValues = {
    username: authModal.username || "", // note this is actually email, aws requires username
    code: "",
  };

  const [errorMessage, setErrorMessage] = useState();

  const handleSubmit = async (values, resetForm) => {
    const { username } = values;
    try {
      await dispatch(
        authForgotPasswordGetCode(username)
      );
      history.push(`?showResetPasswordModal=true&username=${username}`)
    } catch (error) {
      setErrorMessage(getAuthCodeError(error.name));
    }
  };

  return (
    <>
      <Modal
        show
        className="modal-full-width-sm auth-modal"
        onHide={() => dispatch(closeModal())}>
        <Modal.Header className="bg-light">
          <button className="close" onClick={() => dispatch(closeModal())}>
            <i className="fas fa-long-arrow-alt-left d-block d-md-none"></i>
            <span className="d-none d-md-block">&times;</span>
          </button>
          <img
            src={logoSE}
            className="img-fluid mx-auto py-4 d-none d-lg-block"
            alt="Shared Earth logo"
          />
          <h2 className="text-center w-100 d-block d-lg-none mb-0">Reset Password</h2>
        </Modal.Header>
        <Modal.Body>
          <>
            <h4 className="mt-4 mb-3 text-center">
              Please enter an email address
            </h4>
            <ErrorBanner errorMessage={errorMessage} />
            <Formik
              initialValues={initialValues}
              onSubmit={(values, { resetForm }) =>
                handleSubmit(values, resetForm)
              }
            >
              {({ values, setFieldValue, dirty }) => (
                <Form id="profile-form">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      type="username"
                      name="username"
                      placeholder="Enter Email"
                      value={values.username}
                    />
                  </div>
                  <div className="form-group pt-3 mb-1">
                    <button
                      type="submit"
                      form="profile-form"
                      className={`btn btn-save btn-block ${
                        dirty ? "btn-blue-light" : "btn-gray-500 btn-disabled"
                      }`}
                      disabled={dirty ? false : true}
                    >
                      Reset
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ResetPasswordRequestModal.propTypes = {

// }

export default ResetPasswordRequestModal;
