import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewConversation } from "../currentUser/profileActions";
import { history } from "../utils/history";

const ConnectButton = (props) => {
  const { className, children, connectedUser, locationType, onClick = () => {} } = props
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser.profile);

  // look for a conversation with another user
  const haveConversationWithUser = () => {
    const conversationWithUser = currentUser.conversation_links.find((c) => {
      const {
        conversation: { conversation_links },
      } = c;
      return conversation_links.find(
        (link) => link.profile.id === connectedUser.id
      );
    });

    return conversationWithUser;
  };

  return (
    <>
      <button
        type="button"
        className={className}
        onClick={async () => {
          try {
            let conversationId =123
            const conversationWithUser =
              haveConversationWithUser(connectedUser);
            if (!conversationWithUser) {
              conversationId = await dispatch(
                createNewConversation(currentUser.id, connectedUser.id)
              );
            } else {
              conversationId = conversationWithUser.conversation.id
            }

            let messageUrl = `/messages?conversationId=${conversationId}`

            if (locationType) {
              messageUrl += `&locationType=${locationType}`
            }

            onClick();
            history.push(messageUrl);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {children}
      </button>
    </>
  );
};

export default ConnectButton;
