import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { closeDrawer } from "../../common/drawers/drawerActions";
import { getCloudFrontImage } from "../../common/utils/s3bucketHelper";
import { typeOptions } from "../../common/data/selects";
import LocationIcon from "../../common/ui/LocationIcon";
import ConnectButton from "../../common/conversations/ConnectButton";

import userIcon from "../../common/assets/icons/shared-earth-profile-icon-light.svg";
import ProfileMapSelector from "./ProfileMapSelector";

const ProfileDetails = ({ locationId, profile, locationType }) => {
  const dispatch = useDispatch();
  const defaultLocation =
    profile.locations.find(
      (location) => location.id === profile.default_location_id
    ) || profile.locations[0];
  const [activeLocation, setActiveLocation] = useState(defaultLocation);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const locationOptions = profile.locations.map((location) => ({
    label: location.formatted_address,
    value: location.id,
  }));

  const haveLocations = profile.locations.filter(
    (location) => location.type === "have"
  );
  const needLocations = profile.locations.filter(
    (location) => location.type === "need"
  );

  useEffect(() => {
    const location = locationId
      ? profile.locations.find((location) => location.id === locationId)
      : defaultLocation;
    const option = locationOptions.find(
      (option) => option.value === location.id
    );
    setSelectedLocation(option);
    setActiveLocation(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, profile]);

  function onChange(option) {
    const location = profile.locations.find(
      (location) => location.id === option.value
    );
    setSelectedLocation(option);
    setActiveLocation(location);
  }

  return (
    <>
      {/* SUMMARY */}
      <div className="row mb-4 py-4 border-bottom align-items-center">
        <div className="col-12 col-md-9">
          <div className="d-flex align-items-center">
            <div>
              <div className="avatar-wrapper avatar-lg">
                <div
                  style={{
                    backgroundImage: `url(${
                      getCloudFrontImage(profile.avatar_url, {
                        resize: {
                          width: 95,
                          height: 95,
                          fit: "contain",
                        },
                      }) || userIcon
                    })`,
                  }}
                  className={`avatar border-${activeLocation.type}`}
                />
              </div>
            </div>
            
            <div className="ml-3">
              <h4 className="mb-0 fw-bold">
                {profile.first_name} {profile.last_name}
              </h4>
              <div className="d-flex align-items-center">
                <div>
                  <LocationIcon
                    type={activeLocation.type}
                    className="icon-color-only mr-1"
                  />
                </div>
                <div>
                  <span>
                    {
                      typeOptions.find(
                        ({ value }) => value === activeLocation.type
                      ).label
                    }{" "}
                    <span className="d-none d-lg-inline">in {activeLocation.city}</span>
                  </span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="mt-3 mt-md-0 text-lg-end">
            <ConnectButton
              className="btn btn-blue-light ml-lg-auto mt-3 mt-lg-0"
              connectedUser={profile}
              locationType={locationType}
              onClick={() => dispatch(closeDrawer())}
            >
              <div className="d-flex align-items-center justify-content-center text-center">
                <i className="icon-custom icon-messages-white"></i>
                <span>Message <span className="d-inline-block d-lg-none">{profile.first_name}</span></span>
              </div>
            </ConnectButton>
          </div>
        </div>
      </div>

      {/* ABOUT */}
      {profile.about && (
        <div className="row mb-5">
          <div className="col-12">
            {/* <h3>About {profile.first_name}</h3> */}
            <p>{profile.about}</p>
          </div>
        </div>
      )}
      <ProfileMapSelector
        onChange={onChange}
        activeLocation={activeLocation}
        locationOptions={locationOptions}
        selectedLocation={selectedLocation}
        profile={profile}
      />

      {haveLocations.length > 0 && (
        <div className="row my-4 pt-4 border-top">
          <div className="col-12">
            <h3>Gardens</h3>
            {haveLocations.map((location) => (
              <div key={location.id} className="d-flex align-items-center mb-3">
                <div>
                  <LocationIcon type="have" className="icon-xl mr-3" />
                </div>
                <span>{location.formatted_address}</span>
              </div>
            ))}
          </div>
        </div>
      )}

      {needLocations.length > 0 && (
        <div className="row my-4 pt-4 border-top">
          <div className="col-12">
            <h3>Looking for Gardens</h3>
            <p>{profile.first_name} is looking in these areas</p>
            {needLocations.map((location) => (
              <div key={location.id} className="d-flex align-items-center mb-3">
                <div>
                  <LocationIcon type="need" className="icon-xl mr-3" />
                </div>
                <span>{location.formatted_address}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

ProfileDetails.propTypes = {
  profile: PropTypes.instanceOf(Object),
  locationId: PropTypes.number,
};

export default ProfileDetails;
