import React from "react";

const PaymentMessages = ({ messages }) => {
  return (
    <>
      {messages ? (
        <div id="messages" className="alert alert-warning" role="alert">
          <div>{messages.message}</div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PaymentMessages;
