import { gql } from 'graphql-request'
import { GQL_FRAG_LOCATION_FIELDS } from '../locations/fragments';
import { GQL_FRAG_PROFILE_FIELDS } from './fragments';

export const GQL_GET_PROFILE_BY_ID = gql`
  ${GQL_FRAG_PROFILE_FIELDS}
  ${GQL_FRAG_LOCATION_FIELDS}
  query getProfileById($id: Int) {
    profile(where: {id: {_eq: $id}}) {
      ...ProfileFields
      default_location {
        id
        formatted_address
        geom
      }
      locations {
        ...LocationFields
        images {
          id
          path
          user_id
          location_id
        }
      }
    }
  }`;

export const GQL_GET_PROFILE_CONVERSATIONS = gql`
${GQL_FRAG_PROFILE_FIELDS}
${GQL_FRAG_LOCATION_FIELDS}
query getProfileById($id: Int) {
  profile(where: {id: {_eq: $id}}) {
    conversation_links {
      id
      last_read_date
      conversation {
        id
        messages {
          id
          author_id
          created_at
          content
        }
        conversation_links {
          id
          created_at
          last_read_date
          profile {
            id
            first_name
            last_name
            avatar_url
            default_location_id
            locations {
              id
              user_id
              # latitude
              # longitude
              about
              responsibilities # This gives an ID that will need to be mapped
              experience # This gives an ID that will need to be mapped
              compensation # This gives an ID that will need to be mapped
              garden_size # This gives an ID that will need to be mapped
              garden_status # This gives an ID that will need to be mapped
              search_distance # This gives an ID that will need to be mapped
              interested_growing # String value that is json encoded, it needs to be decoded
              type # This gives an ID that will need to be mapped
              formatted_address
              street_name
              street_number
              city
              zip
              state
              country
              geom
              is_enabled
            }
          }
        }
      }
    }
  }
}`;

export const GQL_GET_PROFILE_BY_EMAIL = gql`
  ${GQL_FRAG_PROFILE_FIELDS}
  ${GQL_FRAG_LOCATION_FIELDS}
  query getProfileByEmail($email: String) {
  profile(where: {username: {_eq: $email}}) {
    ...ProfileFields
    default_location {
      id
      formatted_address
      geom
    }
    locations {
      ...LocationFields
      images {
        id
        path
        user_id
        location_id
      }
    }
  }
}`;