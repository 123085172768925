import PropTypes from 'prop-types'
import { getCloudFrontImage } from '../utils/s3bucketHelper'

const AvatarIcon = ({ type, className, onClick, profile }) => {
  const profileImageStorageUrl =
    getCloudFrontImage(profile?.avatar_url, {
      resize: {
        width: 75,
        height: 75,
        fit: 'contain'
      }
    }) || `icon-${type}`

  return (
    <>
      <div
        className={`avatar-wrapper avatar-lg mb-3 mb-md-0 ${
          type === 'have' ? 'avatar-has-space' : 'avatar-needs-space'
        }`}
      >
        <div
          className={`icon-location icon-${type} ${className}`}
          onClick={onClick}
          style={
            profile?.avatar_url
              ? { backgroundImage: `url(${profileImageStorageUrl})` }
              : { backgroundImage: `icon-${type}` }
          }
        />
      </div>
    </>
  )
}

AvatarIcon.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.instanceOf(Function)
}
AvatarIcon.defaultProps = {
  className: '',
  onClick: () => {}
}

export default AvatarIcon
