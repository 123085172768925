import React from 'react'
import HomeNav from '../navs/HomeNav'
import { useSelector } from 'react-redux'

const DefaultLayout = ({ children }) => {
  const isLoggedIn = useSelector((state) =>
    state.currentUser.auth && state.currentUser.profile ? true : false
  );

  return (
    <>
      <div className={ `homepage-layout nav-default ${isLoggedIn ? 'logged-in' : ''}` }>
        <HomeNav />
        { children }
      </div>
    </>
  )
}

export default DefaultLayout
