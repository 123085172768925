import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SideNav from '../navs/SideNav'
import MobileNav from '../navs/MobileNav'
import DrawerWrapper from '../common/drawers/DrawerWrapper.jsx'
import InactvieAccountDashboard from '../modules/InactvieAccountDashboard'

function AuthLayout({ children }) {
  const [expanded, setExpanded] = useState(false)
  const { profile } = useSelector((state) => state.currentUser)
  const path = useSelector((state) =>
    state.router.location.pathname.replace(/\//, '')
  )

  if (!profile) {
    // do nothing if we dont have a profile
    return <></>
  }

  return (
    <>
      <div
        className={`pageHeader d-none d-md-block ${expanded ? 'expanded' : ''}`}
      >
        <button className='nav-trigger' onClick={() => setExpanded(!expanded)}>
          <i className='fas fa-chevron-right'></i>
        </button>
        <SideNav />
      </div>
      <div
        className={`pageContent ${path}-layout ${expanded ? 'expanded' : ''}`}
      >
        <DrawerWrapper />
        {profile?.archived_date ? <InactvieAccountDashboard /> : <>{children}</>}
      </div>
      <MobileNav />
    </>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default AuthLayout
