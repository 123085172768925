import { gql } from 'graphql-request'
import { GQL_FRAG_PROFILE_FIELDS } from '../profile/fragments';
import { GQL_FRAG_LOCATION_FIELDS } from './fragments';

export const GQL_LOAD_LOCATIONS = gql`
  query getAllLocations {
    locations_location {
      id
      formatted_address
      geom
      city
      state
      type
      profile {
        id
        first_name
        last_name
        avatar_url
        share_locations
      }
    }
  }`;

// Sample Query
// query getLocationsAroundLocation {
//   get_locations_around_location(args: {distance_km: 10, geom_obj: {type: "Point", coordinates: [-89.483474, 43.0671419]}}) {
//     id
//   }
// }
export const GQL_GET_LOCATIONS_BY_LOCATION = gql`
  ${GQL_FRAG_LOCATION_FIELDS}
  ${GQL_FRAG_PROFILE_FIELDS}
  query getLocationsAroundLocation($distance: Int!, $location: geometry!) {
    get_locations_around_location(args: {distance_km: $distance, geom_obj: $location}) {
      ...LocationFields
      profile {
        ...ProfileFields
        locations {
          ...LocationFields
          images {
            path
          }
        }
      }
      images {
        path
      }
    }
  }
`;

export const GQL_GET_PUBLIC_LOCATIONS_BY_LOCATION = gql`
  query getLocationsAroundLocation($distance: Int!, $location: geometry!) {
    get_locations_around_location(args: {distance_km: $distance, geom_obj: $location}) {
      type
    }
  }
`;

// query MyQuery {
//   get_locations_from_bounds(args: {latn: 43.1296097575335, lats: 42.94806095023873, lnge: -87.78227659377443, lngw: -88.0306706062256}) {
//     city
//   }
// }

export const GQL_GET_LOCATIONS_FROM_BOUNDS = gql`
  ${GQL_FRAG_LOCATION_FIELDS}
  ${GQL_FRAG_PROFILE_FIELDS}
  query getLocationsFromBounds($lngW: float8, $latS: float8, $lngE: float8, $latN: float8) {
    get_locations_from_bounds(args: {latn: $latN, lats: $latS, lnge: $lngE, lngw: $lngW}) {
      ...LocationFields
      profile {
        ...ProfileFields
        locations {
          ...LocationFields
        }
      }
      images {
        path
      }
    }
  }
`;