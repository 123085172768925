import React from "react";
import PropTypes from "prop-types";
import Zoom from "react-medium-image-zoom";
import { getCloudFrontImage } from "../../common/utils/s3bucketHelper";
import {
  gardenSizeOptions,
  gardenStatusOptions,
  experienceOptions,
  searchDistanceOptions,
  responsibilitiesOptions,
} from "../../common/data/selects";
import LocationIcon from "../../common/ui/LocationIcon";
import GoogleMap from "../../common/ui/Maps/Map";
import LoadingIndicator from "../../common/ui/LoadingIndicator";
import { mapStyle } from "../../common/data/mapStyle";

const LocationDetails = ({ firstName, location, showDetails = true }) => {
  const {
    type,
    images,
    about,
    // compensation,
    garden_size,
    garden_status,
    experience,
    // interested_growing,
    search_distance,
    responsibilities,
  } = location;

  if (!location) return <LoadingIndicator />;

  return (
    <>
      {/* MAP */}
      <div
        className="bg-dark text-white rounded overflow-hidden"
        style={{ width: "100%", height: "25vh" }}
      >
        <GoogleMap
          defaultZoom={16}
          center={[location.geom.coordinates[1], location.geom.coordinates[0]]}
          options={{
            panControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            gestureHandling: "none",
            fullscreenControl: false,
            zoomControl: false,
            styles: mapStyle,
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          <LocationIcon
            key={location.id}
            className={`icon-map`}
            lat={location.geom.coordinates[1]}
            lng={location.geom.coordinates[0]}
            type={location.type}
          />
        </GoogleMap>
      </div>
      {showDetails && (
        <>
          {type && type === "have" ? (
            <>
              {about && (
                <div className="row my-4">
                  <div className="col-12">
                    <h3>About this Garden</h3>
                    <p>{about}</p>
                  </div>
                </div>
              )}
              {images && images.length > 0 && (
                <>
                  <div className="row my-4 py-4 border-top">
                    {images.map((image, i) => {
                      const imgSrc = getCloudFrontImage(image.path, {
                        resize: {
                          width: 500,
                          height: 500,
                          fit: "contain",
                        },
                      })
                      return (
                        <div className="col-lg-4 mb-3" key={i}>
                          <Zoom zoomMargin={40}>
                            <img
                              src={imgSrc}
                              className="img-fluid rounded"
                              alt=""
                            />
                          </Zoom>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              {garden_size && (
                <div className="row my-4 pt-4 border-top">
                  <div className="col-12">
                    <h3>Land Size</h3>
                    {
                      gardenSizeOptions.find(
                        (option) => option.value === garden_size
                      ).label
                    }
                  </div>
                </div>
              )}

              {garden_status && (
                <div className="row my-4 pt-4 border-top">
                  <div className="col-12">
                    <h3>Land Status</h3>
                    {
                      gardenStatusOptions.find(
                        (option) => option.value === garden_status
                      ).label
                    }
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {search_distance && (
                <div className="row my-4 pt-4 border-top">
                  <div className="col-12">
                    <h3>How Far {firstName} is Looking</h3>
                    {
                      searchDistanceOptions.find(
                        (option) => option.value === search_distance
                      ).label
                    }
                  </div>
                </div>
              )}

              {/* TODO, We need to loop through these */}
              {/* {interested_growing && (
                  <div className="row my-4 pt-4 border-top">
                    <div className="col-12">
                      <h3>What {firstName} likes to Grow</h3>
                      {interestedGrowingOptions.find(option => option.value === interested_growing).label}
                    </div>
                  </div>
                )}
            */}

              {experience && (
                <div className="row my-4 pt-4 border-top">
                  <div className="col-12">
                    <h3>Experience</h3>
                    {
                      experienceOptions.find(
                        (option) => option.value === experience
                      ).label
                    }
                  </div>
                </div>
              )}

              {responsibilities && (
                <div className="row my-4 pt-4 border-top">
                  <div className="col-12">
                    <h3>Who Does What</h3>
                    {
                      responsibilitiesOptions.find(
                        (option) => option.value === responsibilities
                      ).label
                    }
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

LocationDetails.propTypes = {
  location: PropTypes.instanceOf(Object),
  firstName: PropTypes.string,
};

LocationDetails.defaultProps = {
  firstName: "Gardener",
};

export default LocationDetails;
